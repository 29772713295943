import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Formik, Field, Form } from "formik";
import Link from "../../services/routing/components/Link";
import { object, ref, string } from "yup";
import { useTranslation } from "react-i18next";
import { PRIVATE } from "../../routes/private";
import { useToasts } from "../../services/toast-notifications";
import { useAuth, AuthAPIConnected } from "../../services/auth/apiProvider";
import { AxiosError } from "axios";
import SubmitButton from "../../services/forms/SubmitButton";
import ValidationsErrors from "../../services/forms/ValidationsErrors";

const Profile: React.FC<RouteComponentProps> = () => {
  const { user, updatePassword } = useAuth() as AuthAPIConnected;
  const { error, success } = useToasts();
  const { t } = useTranslation(["auth"]);

  const PasswordSchema = useMemo(
    () =>
      object().shape({
        oldPassword: string().label(t("auth:OLD_PASSWORD")).required(),
        newPassword: string()
          .label(t("auth:NEW_PASSWORD"))
          .required()
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            { message: { key: "INVALID_PASSWORD" } },
          ),
        newPasswordConf: string()
          .label(t("auth:NEW_PASSWORD_CONFIRMATION"))
          .required()
          .equals([ref("newPassword")], {
            key: "PASSWORDS_NOT_EQUALS",
          }),
      }),
    [t],
  );

  return (
    <div className={"page-content container"}>
      <Link className={"link"} to={PRIVATE}>
        &lt; Retour
      </Link>
      <div className={"section-1 margin-top"}>
        <h2 className={"section-title"}>Profil</h2>
        <div className={"profile-display"}>
          <div>
            <strong>Email</strong>
            <br />
            {user.email}
          </div>
          <div>
            <strong>Nom</strong>
            <br />
            {user.lastname}
          </div>
          <div>
            <strong>Prénom</strong>
            <br />
            {user.firstname}
          </div>
        </div>
      </div>

      <div className={"section-1 margin-top"}>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            newPasswordConf: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            updatePassword(values.oldPassword, values.newPassword).then(
              () => {
                setSubmitting(false);
                success(t("auth:CHANGE_PASSWORD.TOAST_SUCCESS"));
              },
              (err: AxiosError) => {
                setSubmitting(false);
                if (err.response?.status === 412) {
                  error(t("auth:CHANGE_PASSWORD.TOAST_INVALID_OLD_PASSWORD"));
                } else {
                  error(t("auth:CHANGE_PASSWORD.TOAST_ERROR"));
                }
              },
            );
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={PasswordSchema}
        >
          <Form autoComplete={"off"}>
            <div className={"section"}>
              <h2 className={"section-title"}>{t("auth:PASSWORD")}</h2>
              <div>
                <div>
                  <p className={"info"}>
                    Renseignez les 3 champs ci-dessous si vous souhaitez
                    modifier votre mot de passe.
                  </p>
                </div>
                <div className={"grid section"}>
                  <div className={"col-m-1-3"}>
                    <div>
                      <div>
                        <label className={"input-label"}>
                          {t("auth:OLD_PASSWORD")} *
                        </label>
                        <Field
                          className={"input"}
                          id={"oldPassword"}
                          name={"oldPassword"}
                          type={"password"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"col-m-1-3"}>
                    <label className={"input-label"}>
                      {t("auth:NEW_PASSWORD")} *
                    </label>
                    <Field
                      className={"input"}
                      id={"newPassword"}
                      name={"newPassword"}
                      type={"password"}
                    />
                    <p className={"input-tip"}>
                      8 caractères min. dont au moins une lettre, un chiffre et
                      un caractère spécial.
                    </p>
                  </div>
                  <div className={"col-m-1-3"}>
                    <label className={"input-label"}>
                      {t("auth:NEW_PASSWORD_CONFIRMATION")} *
                    </label>
                    <Field
                      className={"input"}
                      id={"newPasswordConf"}
                      name={"newPasswordConf"}
                      type={"password"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <ValidationsErrors />
            </div>

            <div className={"center"}>
              <SubmitButton className={"btn-1"}>
                Mettre à jour mon mot de passe
              </SubmitButton>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Profile;
