import { FunctionComponent } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import NavLink from "../../../services/routing/components/NavLink";
import {
  COMPANIES,
  COMPANIES_LINK,
  COMPANY_MANAGEMENT,
  USERS,
  USERS_LINK,
} from "../../../routes/companyManagement";
import Companies from "./Companies";
import CompanyUsers from "./CompaniesUsers";
import {
  CompanyManagementContext,
  useProvideCompanyManagement,
} from "../../../services/company-management/apiProvider";

const CompanyManagement: FunctionComponent<RouteComponentProps> = () => {
  const companyManagement = useProvideCompanyManagement();

  return (
    <CompanyManagementContext.Provider value={companyManagement}>
      <>
        <div className={"main-nav"}>
          <ul>
            <li>
              <NavLink to={COMPANIES_LINK}>Entreprises</NavLink>
            </li>
            <li>
              <NavLink to={USERS_LINK}>Utilisateurs</NavLink>
            </li>
          </ul>
        </div>
        <div className={"container page-content"}>
          <Router>
            <Redirect
              from={COMPANY_MANAGEMENT}
              to={COMPANIES_LINK}
              default
              noThrow
            />
            <Companies path={COMPANIES} />
            <CompanyUsers path={USERS} />
          </Router>
        </div>
      </>
    </CompanyManagementContext.Provider>
  );
};

export default CompanyManagement;
