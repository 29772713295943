import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FunctionComponent,
} from "react";
import { useFormikContext } from "formik";
import Loading from "../routing/components/Loading";

interface SubmitButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

const SubmitButton: FunctionComponent<SubmitButtonProps> = (props) => {
  const { isSubmitting } = useFormikContext();

  return (
    <button {...props} disabled={isSubmitting} type={"submit"}>
      {isSubmitting ? <Loading debounce={false} /> : props.children}
    </button>
  );
};

export default SubmitButton;
