import React, { FunctionComponent } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import Login from "./Login";
import {
  LOGIN,
  LOGIN_LINK,
  LOST_PASSWORD,
  LOST_PASSWORD_LINK,
  PRIVACY,
} from "../../routes/public";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import NavLink from "../../services/routing/components/NavLink";
import LostPassword from "./LostPassword";
import logo from "../../assets/img/logo.svg";
import PrivacyPage from "./Privacy";

const Public: FunctionComponent<RouteComponentProps> = () => {
  return (
    <div className={"auth-layout"}>
      <header className={"header"}>
        <NavLink to={LOGIN_LINK}>
          <img alt={"logo 2U"} className={"logo-header"} src={logo} />
        </NavLink>
      </header>

      <main className={"content"}>
        <Router>
          <Redirect from={"/"} to={LOGIN_LINK} default noThrow />
          <Login path={LOGIN} />
          <LostPassword path={LOST_PASSWORD} />
          <PrivacyPage path={PRIVACY} />
        </Router>
      </main>

      <footer className={"footer"}>
        <nav className={"nav"}>
          <ul>
            <li>
              <NavLink to={LOGIN_LINK}>Connexion</NavLink>
            </li>
            <li>
              <NavLink to={LOST_PASSWORD_LINK}>Mot de passe oublié</NavLink>
            </li>
          </ul>
        </nav>
        <p className={"copyright"}>&copy; copyright 2U 2021</p>
      </footer>
    </div>
  );
};

export default withRedirectToPrivate(Public);
