import { PRIVATE } from "./private";

export const COMPANY_MANAGEMENT = "/company-management" as const;
export const COMPANY_MANAGEMENT_LINK = `${PRIVATE}${COMPANY_MANAGEMENT}` as const;

export const COMPANY_DASHBOARD = "/company-dashboard" as const;
export const COMPANY_DASHBOARD_LINK = `${PRIVATE}${COMPANY_DASHBOARD}` as const;

export const COMPANIES = "/companies" as const;
export const COMPANIES_LINK = `${COMPANY_MANAGEMENT_LINK}${COMPANIES}` as const;

export const USERS = "/users" as const;
export const USERS_LINK = `${COMPANY_MANAGEMENT_LINK}${USERS}` as const;
