import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useToasts } from "../../services/toast-notifications";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import { object, string } from "yup";
import Link from "../../services/routing/components/Link";
import { LOGIN_LINK } from "../../routes/public";
import { useAuth } from "../../services/auth/apiProvider";
import ValidationsErrors from "../../services/forms/ValidationsErrors";
import SubmitButton from "../../services/forms/SubmitButton";

const LostPassword: React.FC<RouteComponentProps> = () => {
  const { lostPassword } = useAuth();
  const { error, success } = useToasts();
  const { t } = useTranslation(["auth"]);

  const LostPasswordSchema = useMemo(
    () =>
      object().shape({
        email: string().label(t("auth:EMAIL")).required().email(),
      }),
    [t],
  );

  return (
    <div className={"auth-card"}>
      <div className={"card-head"}>
        <h1 className={"card-title"}>Mot de passe oublié</h1>
      </div>
      <div className={"card-body"}>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values, { setSubmitting }) => {
            lostPassword(values.email).then(
              () => {
                setSubmitting(false);
                success(t("auth:LOST_PASSWORD.TOAST_SUCCESS"));
              },
              () => {
                setSubmitting(false);
                error(t("auth:LOST_PASSWORD.TOAST_ERROR"));
              },
            );
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={LostPasswordSchema}
        >
          <Form>
            <div>
              <label className={"input-label"}>{t("auth:EMAIL")} *</label>
              <Field
                className={"input"}
                name={"email"}
                placeholder={"e.g. john.doe@mail.com"}
                type={"email"}
              />
            </div>

            <div>
              <ValidationsErrors />
            </div>

            <div className={"center"}>
              <SubmitButton className={"btn-1"}>Envoyer</SubmitButton>
              <Link className={"btn-link"} to={LOGIN_LINK}>
                Retour à la connexion
              </Link>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default withRedirectToPrivate(LostPassword);
