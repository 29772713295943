import { RouteComponentProps } from "@reach/router";

const PrivacyPage: React.FC<RouteComponentProps> = () => {
  return (
    <div className={"privacy-page"}>
      <h1>Politique de Confidentialité</h1>

      <section>
        <h2>1. Objectifs de la politique de confidentialité</h2>
        <p>
          Le présent document a pour objet de permettre aux utilisateurs
          (ci-après désignés « les Utilisateurs ») d’être informés de
          l’utilisation de leurs données personnelles effectuée par la société «
          2U », S.A.S. au capital de 10.000 euros, sise 2 Rue des Fontaines
          42290 SORBIERS, immatriculée au registre du commerce et des sociétés
          sous le numéro 903 337 939 au RCS de SAINT-ÉTIENNE, dans le cadre de
          leur utilisation du site internet à l’adresse
          https://www.2urelaxing.com/index.html ainsi que de l’application « 2U
          ».
        </p>
        <p>
          La responsable du traitement des données désignée est la société « 2U
          » qui est représentée par sa Présidente, Madame Sylvie PAUZE.
        </p>
        <p>Important :</p>
        <p>
          La société « 2U » n’utilise les données personnelles des Utilisateurs
          que dans les cas prévus par la réglementation en vigueur, à savoir :
        </p>
        <ul>
          <li>L’exécution d’un contrat conclu avec l’Utilisateur, et/ou</li>
          <li>Le respect d’une obligation légale, et/ou</li>
          <li>
            Le consentement de l’Utilisateur à l’utilisation de ses données,
            et/ou
          </li>
          <li>
            L’existence d’un intérêt légitime à utiliser les données de
            l’Utilisateur. L’intérêt légitime est un ensemble de raisons
            commerciales ou d’affaires qui justifie l’utilisation des données
            par la société « 2U ».
          </li>
        </ul>
      </section>

      <section>
        <h2>2. Droits accordés aux Utilisateurs</h2>
        <ul>
          <li>
            Droit d'accès : le droit d'être informé et de demander l'accès aux
            données personnelles traitées
          </li>
          <li>
            Droit de rectification : le droit de demander de modifier ou de
            mettre à jour les données personnelles des Utilisateurs lorsqu'elles
            sont inexactes ou incomplètes
          </li>
          <li>
            Droit à l’oubli : le droit de demander de supprimer définitivement
            les données personnelles des Utilisateurs
          </li>
          <li>
            Droit de restriction : le droit de demander d'arrêter temporairement
            ou définitivement le traitement de tout ou partie des données
            personnelles des Utilisateurs
          </li>
          <li>
            Droit de refus :
            <ul>
              <li>
                le droit de refuser à tout moment le traitement des données
                personnelles de l’Utilisateur pour des raisons qui lui sont
                propres
              </li>
              <li>
                le droit de refuser le traitement des données personnelles de
                l’Utilisateur à des fins de marketing direct
              </li>
            </ul>
          </li>
          <li>
            Droit à la portabilité des données : le droit de demander une copie
            des données personnelles de l’Utilisateur au format électronique et
            le droit de transmettre ces données personnelles pour une
            utilisation par un service tiers
          </li>
          <li>
            Droit de ne pas être soumis à la prise de décision automatisée : le
            droit de ne pas être soumis à une décision basée uniquement sur la
            prise de décision automatisée, y compris le profilage, dans le cas
            où la décision aurait un effet juridique sur l’Utilisateur ou
            produirait un effet significatif similaire
          </li>
        </ul>
        <p>
          L’Utilisateur peut exercer à tout moment ces droits en effectuant
          toute demande auprès de la société « 2U », qui est joignable :
        </p>
        <ul>
          <li>
            Par courrier postal à l’adresse : 2 Rue des Fontaines, 42290
            SORBIERS
          </li>
          <li>Par Email à l’adresse : contact@2urelaxing.com</li>
          <li>Par téléphone au : 06 47 38 04 64</li>
        </ul>
        <p>
          En cas de réponse insatisfaisante, l’Utilisateur est informé qu’il
          peut porter réclamation auprès de la Commission Nationale de
          l’Informatique et des Libertés (CNIL).
        </p>
      </section>

      <section>
        <h2>3. Procédure de collecte des données personnelles</h2>
        <p>
          Les données personnelles de l’Utilisateur sont collectées par
          l’intermédiaire des procédures suivantes :
        </p>
        <ul>
          <li>
            Données transmises directement par l’Utilisateur :
            <p>
              Lors de sa demande de contact sur le site internet et/ou sur
              l’application ou à l’occasion de la souscription à un abonnement,
              l’Utilisateur est amené à compléter des champs notamment composés
              d’une adresse Email valide, un nom, un prénom, un numéro de
              téléphone, une adresse postale, une date de naissance, le cas
              échéant un nom de société.
            </p>
          </li>
          <li>
            Données recueillies à l’occasion de la relation commerciale :
            <p>
              Les données qui sont recueillies à l’occasion de la relation
              commerciale avec l’Utilisateur.
            </p>
          </li>
          <li>
            Données recueillies automatiquement :
            <p>
              Cela concerne les données recueillies par l’intermédiaire de
              cookies, par l’intermédiaire des technologies standard d’Internet.
            </p>
          </li>
        </ul>
        <p>
          Les données collectées sont nécessaires à l’utilisation des services
          délivrés par la société « 2U ». Il est rappelé que l’Utilisateur
          dispose à tout moment de la possibilité de changer d’avis et de
          retirer son autorisation à ladite collecte de données.
        </p>
      </section>

      <section>
        <h2>4. Catégorie de données personnelles collectées</h2>
        <p>Lors de la souscription à un abonnement :</p>
        <p>
          Ce sont les données personnelles fournies par l’Utilisateur ou
          recueillies afin de lui permettre de s’inscrire et d’utiliser les
          services. Elles comprennent une adresse Email valide, un nom, un
          prénom, un numéro de téléphone, une adresse postale, une date de
          naissance, le cas échéant un nom de société.
        </p>
        <p>Lors de l’utilisation des services :</p>
        <p>
          Ce sont les données personnelles de l’Utilisateur qui sont collectées
          lors de l’utilisation des services, elles peuvent comprendre :
        </p>
        <ul>
          <li>
            Les interactions avec les services proposés par la société « 2U »,
            comme par exemple l’identification, la durée des contenus consultés
            ainsi que le nombre de visionnage des contenus
          </li>
          <li>Les interactions effectuées avec le service client</li>
          <li>Des données techniques telles que URL, adresses IP</li>
          <li>Le nom et le prénom</li>
          <li>Une adresse Email</li>
          <li>Une adresse postale</li>
          <li>Un numéro de téléphone</li>
          <li>Dénomination sociale (pour les sociétés)</li>
        </ul>
        <p>
          Le site utilise la technologie des cookies pour améliorer la
          navigation ainsi que l'expérience des Utilisateurs. Lors de la
          consultation de celui-ci, ces cookies ne seront pas collectés. À tout
          moment, les visiteurs peuvent décider d'accepter ou de refuser
          l'utilisation des cookies ainsi que modifier leurs préférences.
        </p>
        <p>
          Le cookie possède une durée de validité qui varie en fonction du type
          de cookie et permet la reconnaissance du visiteur lors d'une prochaine
          utilisation du site internet. En tout état de cause, cette durée
          n'excède pas 13 mois.
        </p>
        <p>
          Les visiteurs peuvent décider d'accepter ou de refuser les cookies
          lors de leur navigation sur le site. À cet effet, la société « 2U »
          fait apparaître, sur son site internet et/ou sur son application, un
          bandeau d'information préalable invitant le visiteur à accepter les
          cookies.
        </p>
        <p>
          La société « 2U » ne collecte aucune donnée sensible concernant
          l’Utilisateur. Sont considérées comme des données sensibles :
          l’origine raciale ou ethnique, les opinions politiques, les croyances
          religieuses ou philosophiques, l’adhésion à un syndicat, les données
          relatives à la santé ou à l’orientation sexuelle.
        </p>
      </section>

      <section>
        <h2>5. Utilisation des données personnelles de l’Utilisateur</h2>
        <p>
          Lors de l’utilisation des services sur le site internet et/ou sur
          l’application, la société « 2U » traite les données personnelles de
          l’Utilisateur par l’intermédiaire de l’usage de différentes
          technologies, suivant la description, la base juridique et les
          catégories de données personnelles suivantes :
        </p>
        <table className={"table"}>
          <thead>
            <tr>
              <th>
                Motif du traitement des données personnelles de l’Utilisateur
              </th>
              <th>Base juridique du traitement opéré</th>
              <th>
                Catégories de données personnelles utilisées pour l’objectif du
                traitement
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Fourniture des services, du site internet, de l’application,
                amélioration et personnalisation de l’expérience de
                l’Utilisateur
              </td>
              <td>
                - Exécution du contrat
                <br />- Intérêts légitimes
                <br />- Consentement
              </td>
              <td>
                - Données d’inscription de l’Utilisateur
                <br />- Données d’utilisation des services par l’Utilisateur
              </td>
            </tr>
            <tr>
              <td>
                Comprendre comment l’Utilisateur accède aux services, au site
                internet et à l’application et comment il les utilise, afin de
                garantir la fonctionnalité technique des services, développer de
                nouveaux produits et services
              </td>
              <td>
                - Exécution du contrat
                <br />- Intérêts légitimes
              </td>
              <td>
                - Données d’inscription de l’Utilisateur
                <br />- Données d’utilisation des services par l’Utilisateur
              </td>
            </tr>
            <tr>
              <td>Communications entre l’Utilisateur et le service clients</td>
              <td>
                - Exécution du contrat
                <br />- Intérêts légitimes
              </td>
              <td>
                - Données d’inscription de l’Utilisateur
                <br />- Données d’utilisation des services par l’Utilisateur
              </td>
            </tr>
            <tr>
              <td>
                Fourniture de fonctionnalités, d’informations, de publicité ou
                de tout contenu à l’Utilisateur
              </td>
              <td>- Consentement</td>
              <td>- Données d’utilisation des services par l’Utilisateur</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <h2>6. Partage des données personnelles</h2>
        <h3>Informations accessibles publiquement :</h3>
        <p>NEANT</p>
        <h3>Personnes susceptibles d’avoir accès à vos données :</h3>
        <p>
          Les collaborateurs de la société « 2U » membres des services
          administratifs, comptabilité et contrôle de gestion, informatique,
          community management, marketing et commercial.
        </p>
        <p>
          Les sous-traitants et organismes externes, qui assurent des
          prestations pour le compte de la société « 2U », telles que la
          personnalisation des contenus du site, la réalisation d’opérations de
          maintenance et de développements techniques, la fourniture de
          solutions analytiques ou de statistiques de mesure d’audience.
        </p>
        <p>
          Leur accès à vos données se fait sur la base de contrats signés
          faisant mention des obligations leur incombant en matière de
          protection de la sécurité et de la confidentialité des données.
        </p>
        <p>
          Les autorités de police, autorités judiciaires ou administratives
          peuvent également avoir accès à vos données lorsque la société « 2U »
          a l’obligation légale de le faire ou afin de garantir les droits, les
          biens et la sécurité de sa structure.
        </p>
        <p>
          Il est précisé que l’hébergement du site internet et des données
          personnelles est effectué chez :
          <br />
          OVH – SAS
          <br />2 rue Kellermann
          <br />
          BP 80157
          <br />
          59100 ROUBAIX
        </p>
        <h3>Informations que la société « 2U » peut partager :</h3>
        <ul>
          <li>Nom et prénom</li>
          <li>Adresse Email</li>
          <li>Adresse postale</li>
          <li>Numéro de téléphone</li>
          <li>Date de naissance</li>
          <li>Adresse IP</li>
          <li>Nombre de vidéos vu par l’Utilisateur</li>
          <li>Dénomination sociale (pour les sociétés)</li>
          <li>Cookies</li>
        </ul>
      </section>

      <section>
        <h2>7. Conservation et suppression des données</h2>
        <p>
          Les données personnelles collectées auprès de l’Utilisateur sont
          conservées pendant une durée conforme aux dispositions légales et
          proportionnelle aux finalités pour lesquelles elles ont été
          enregistrées. Elles sont conservées le temps nécessaire à la
          fourniture des services et à des fins légitimes et commerciales
          essentielles, telles que le maintien de services performants, la prise
          de décisions basée sur les données concernant les nouvelles
          fonctionnalités, la conformité avec les obligations légales. Les
          durées de conservation varient selon que la société « 2U » a une
          relation de nature contractuelle en cours avec l’Utilisateur (client
          actif), ou que la société « 2U » a eu par le passé, avec
          l’Utilisateur, une relation contractuelle (client inactif) ou qu’il
          n’y a jamais eu de relation de ce type (prospect).
        </p>
        <table>
          <thead>
            <tr>
              <th>Catégories de données</th>
              <th>Finalités</th>
              <th>Durées de conservation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Données relatives à un prospect</td>
              <td>Constitution et gestion d’un fichier de prospects</td>
              <td>
                3 ans à compter de la collecte des données ou du dernier contact
                émanant du prospect
              </td>
            </tr>
            <tr>
              <td>Données relatives à un client actif</td>
              <td>Gestion du compte client</td>
              <td>Pendant toute la durée de la relation contractuelle</td>
            </tr>
            <tr>
              <td>Données relatives à un client inactif</td>
              <td>Envoi d’informations sur l’évolution des offres</td>
              <td>
                3 ans maximum à compter de la désinscription ou du dernier
                contact émanant du client
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Sur simple demande de l’Utilisateur, la société « 2U » supprimera ou
          rendra anonyme les données personnelles afin que ce dernier ne puisse
          plus être identifié, sauf dispositions légales contraires, notamment
          dans les conditions suivantes :
        </p>
        <ul>
          <li>
            S’il existe une difficulté en cours avec l’Utilisateur concernant
            son compte, notamment un litige non résolu. Les données sont
            conservées le temps de la résolution du litige.
          </li>
          <li>
            La société « 2U » est tenue de conserver les données personnelles du
            fait d’obligations juridiques, fiscales, de comptabilité. Les
            données sont conservées pendant la période légale qui s’impose.
          </li>
          <li>
            Si les données sont requises à l’effet de maintenir la sécurité des
            Utilisateurs.
          </li>
        </ul>
      </section>

      <section>
        <h2>8. Transfert dans d’autres pays</h2>
        <p>NEANT</p>
      </section>

      <section>
        <h2>9. Sécurité des données personnelles</h2>
        <p>
          La société « 2U », en sa qualité de responsable du traitement, met en
          œuvre des mesures techniques et organisationnelles appropriées
          conformément aux dispositions légales applicables, pour protéger les
          données personnelles des Utilisateurs contre l’altération, la perte
          accidentelle ou illicite, l’utilisation, la divulgation ou l’accès non
          autorisé, et notamment :
        </p>
        <ul>
          <li>
            La sensibilisation aux exigences de confidentialité des
            collaborateurs amenés à accéder aux données personnelles
          </li>
          <li>
            La sécurisation de l’accès aux locaux et aux plates-formes
            informatiques
          </li>
          <li>
            La mise en œuvre d’une politique générale de sécurité informatique
            de l’entreprise
          </li>
          <li>
            La sécurisation de l’accès, du partage et du transfert des données
          </li>
          <li>
            Le haut degré d’exigence en matière de protection des données lors
            de la sélection des sous- traitants et partenaires
          </li>
        </ul>
      </section>

      <section>
        <h2>10. Mineurs</h2>
        <p>
          La société « 2U » rappelle aux Utilisateurs, conformément à la
          règlementation applicable, qu’elle a mis en place un processus relatif
          au traitement des données des personnes mineures au sens du règlement
          européen. Ainsi, lorsque l’enfant est âgé de moins de 16 ans, le
          traitement des données personnelles le concernant n’est licite que si
          le consentement est donné par le titulaire de la responsabilité
          parentale. En tout état de cause, la société « 2U » fait ses meilleurs
          efforts, compte tenu des moyens technologiques disponibles, afin de ne
          pas recueillir de données personnelles d’enfants âgés de moins de 16
          ans, sans l’accord préalable du titulaire de la responsabilité
          parentale.
        </p>
      </section>

      <section>
        <h2>11. Modifications de la politique de confidentialité</h2>
        <p>
          La société « 2U» peut apporter, à tout moment, toutes modifications à
          la présente politique de confidentialité. Dans une telle situation,
          l’Utilisateur en sera informé par l’intermédiaire de notifications
          adaptées, notamment par Email.
        </p>
      </section>

      <section>
        <h2>12. Consentement à la collecte de données personnelles</h2>
        <p>
          Par l’acceptation des présentes, l’Utilisateur autorise la société «
          2U » à procéder au traitement de ses données personnelles nécessaires
          au traitement de son dossier, dans les conditions et suivants les
          modalités convenues aux présentes.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPage;
