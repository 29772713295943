import { compile, PathFunction } from "path-to-regexp";

const cache: Record<string, PathFunction<Record<string, unknown>>> = {};
const cacheLimit = 10000;
let cacheCount = 0;

function compilePath(path: string): PathFunction<Record<string, unknown>> {
  if (cache[path]) return cache[path];

  const generator = compile(path);

  if (cacheCount < cacheLimit) {
    cache[path] = generator;
    cacheCount++;
  }

  return generator;
}

function generatePath(
  path = "/",
  params = {},
  queryParams: Record<string, string> | undefined = undefined,
): string {
  if (path === "/") {
    return path;
  } else {
    const compiledPath = compilePath(path)(params);
    if (queryParams === undefined || Object.keys(queryParams).length < 0)
      return compiledPath;
    const searchParams = new URLSearchParams(queryParams);
    return `${compiledPath}?${searchParams.toString()}`;
  }
}

export default generatePath;
