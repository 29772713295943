import React, { ReactElement, useMemo } from "react";
import { ExtractRouteParams } from "../types";
import { Link as RRLink, LinkProps as RRLinkProps } from "@reach/router";
import generatePath from "../generatePath";
import { cx } from "@emotion/css";

interface OwnProps<T extends string>
  extends React.PropsWithoutRef<RRLinkProps<never>> {
  to: T;
  params?: never;
  activeClassName?: string;
  queryParams?: Record<string, string>;
}
interface OwnPropsWithParams<T extends string>
  extends React.PropsWithoutRef<RRLinkProps<never>> {
  to: T;
  params: ExtractRouteParams<T>;
  activeClassName?: string;
  queryParams?: Record<string, string>;
}

type Props<T extends string> = (T extends
  | `${string}:${string}/${string}`
  | `${string}:${string}`
  ? OwnPropsWithParams<T>
  : OwnProps<T>) &
  React.RefAttributes<HTMLAnchorElement>;

const NavLink = <T extends string>(props: Props<T>): ReactElement => {
  const { activeClassName, to, params, queryParams, ...otherProps } = props;

  const path = useMemo(() => generatePath(to, params, queryParams), [
    to,
    params,
    queryParams,
  ]);

  return (
    <RRLink
      {...otherProps}
      getProps={({ isCurrent }) =>
        isCurrent && {
          className: cx([
            props.className,
            activeClassName ? activeClassName : "active",
          ]),
        }
      }
      to={path}
    />
  );
};

export default NavLink;
