import { DependencyList, useEffect, useState } from "react";

interface Loader {
  loading: boolean;
  error: unknown;
  reload(): void;
}

export default function useLoader(
  loader: () => Promise<unknown>,
  deps?: DependencyList,
): Loader {
  const [loadingState, setLoadingState] = useState<{
    loading: boolean;
    error: unknown;
  }>({ loading: true, error: null });

  const reload = () => {
    let canceled = false;
    setLoadingState({ loading: true, error: null });
    loader().then(
      () => {
        if (!canceled) setLoadingState({ loading: false, error: null });
      },
      (errors) => {
        if (!canceled) setLoadingState({ loading: false, error: errors });
      },
    );

    return () => {
      canceled = true;
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reload, deps);

  return {
    ...loadingState,
    reload,
  };
}
