import { FunctionComponent, useCallback } from "react";
import { Form, Formik, Field } from "formik";
import { CompanyList, Company } from "../company";
import { useCompanyManagement } from "../apiProvider";
import { useToasts } from "../../toast-notifications";
import { useTranslation } from "react-i18next";
import { useConfirmation } from "../../ui/ConfirmationDialog";
import { object, string } from "yup";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";

interface Props {
  company: CompanyList;
}

const CompanySchema = object({
  name: string().label("nom").required(),
});

const CompanyForm: FunctionComponent<Props> = ({ company }) => {
  const {
    deleteCompany: apiDeleteCompany,
    updateCompany: apiUpdateCompany,
  } = useCompanyManagement();

  const { t } = useTranslation(["companyManagement"]);
  const { error: toastError, success: toastSuccess } = useToasts();
  const { confirm } = useConfirmation();

  const deleteCompany = useCallback(
    (company: CompanyList) => {
      if (company.userCount === 0) {
        confirm(
          t("companyManagement:DELETE_COMPANY.CONFIRMATION"),
          () => {
            return apiDeleteCompany(company.id).then(
              () => {
                toastSuccess(
                  t("companyManagement:DELETE_COMPANY.TOAST_SUCCESS"),
                );
              },
              () =>
                toastError(t("companyManagement:DELETE_COMPANY.TOAST_ERROR")),
            );
          },
          true,
        );
      } else {
        toastError(t("companyManagement:DELETE_COMPANY.COMPANY_HAVE_USERS"));
      }
    },
    [apiDeleteCompany, confirm, t, toastError, toastSuccess],
  );

  const updateCompany = useCallback(
    (company: Company) =>
      apiUpdateCompany(company.id, company).then(
        () => toastSuccess(t("companyManagement:UPDATE_COMPANY.TOAST_SUCCESS")),
        () => toastError(t("companyManagement:UPDATE_COMPANY.TOAST_ERROR")),
      ),
    [apiUpdateCompany, t, toastError, toastSuccess],
  );

  return (
    <Formik
      initialValues={{ ...company, licences: [] }}
      onSubmit={(values, { setSubmitting }) => {
        updateCompany({
          id: company.id,
          ...CompanySchema.validateSync(values),
        }).finally(() => {
          setSubmitting(false);
        });
      }}
      validateOnBlur={false}
      validateOnMount={false}
      validationSchema={CompanySchema}
    >
      <Form className={"table-grid-row"}>
        <div className={"card-body"}>
          <div className={"form-block"}>
            <label className={"input-label"}>Nom de l'entreprise</label>
            <Field className={"input"} name={"name"} />
          </div>
        </div>

        <div>
          <ValidationsErrors />
        </div>

        <div className={"card-footer"}>
          <SubmitButton className={"btn-1"}>Sauvegarder</SubmitButton>
          <button
            className={"btn-danger"}
            onClick={() => deleteCompany(company)}
            type={"button"}
          >
            Supprimer
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default CompanyForm;
