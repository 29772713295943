import React from "react";
import { Redirect } from "@reach/router";
import { PRIVATE } from "../../../routes/private";
import { PUBLIC } from "../../../routes/public";
import { useAuth } from "../apiProvider";

function withRedirectToPrivate<P, C extends React.ComponentType<P>>(
  WrappedComponent: C,
): C {
  return ((props: P) => {
    const auth = useAuth();

    if (auth.user !== null)
      return <Redirect from={PUBLIC} to={PRIVATE} noThrow />;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <WrappedComponent {...(props as P)} />;
  }) as C;
}

export default withRedirectToPrivate;
