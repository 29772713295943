export function orderByField<P, K extends keyof P>(
  field: K,
  asc?: boolean,
): (a: P, b: P) => number {
  return asc
    ? function (a: P, b: P) {
        return a[field] > b[field] ? -1 : 1;
      }
    : function (a: P, b: P) {
        return a[field] > b[field] ? 1 : -1;
      };
}

export function deepSearch<E>(element: E, word: string): boolean {
  if (!element) return false;
  if (typeof element === "string") {
    return element.toLowerCase().indexOf(word) >= 0;
  } else if (typeof element === "number") {
    return (
      element.toString(10).indexOf(word) >= 0 || parseFloat(word) === element
    );
  } else if (Array.isArray(element)) {
    return element.some((subElement) => deepSearch(subElement, word));
  } else if (typeof element === "object") {
    const properties = Object.getOwnPropertyNames(element) as (keyof E)[];
    for (const i in properties) {
      if (deepSearch(element[properties[i]], word)) return true;
    }
  }
  return false;
}

export function searchGenerator<E>(
  expression: string,
  mapper?: (element: E) => unknown,
): (element: E) => boolean {
  if (expression.length === 0) return () => true;
  const words = expression.split(" ").map((e) => e.toLowerCase());

  return mapper
    ? (element: E) => {
        const mappedElement = mapper(element);
        return words.every((w) => deepSearch(mappedElement, w));
      }
    : (element: E) => words.every((w) => deepSearch(element, w));
}

export function deleteFromArrayAndReturn<T>(
  array: Array<T>,
  index: number,
): Array<T> {
  const newArray = array.slice(0);
  newArray.splice(index, 1);
  return newArray;
}

export function replaceInArrayAndReturn<T>(
  array: Array<T>,
  index: number,
  element: T,
): Array<T> {
  const newArray = array.slice(0);
  newArray[index] = element;
  return newArray;
}

export function hasDuplicates(array: Array<unknown>): boolean {
  return array.some(
    (element, index) =>
      array.slice(index + 1, array.length).indexOf(element) >= 0,
  );
}
