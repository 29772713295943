import axios, { AxiosPromise } from "axios";
import { LoggedUser, User, UserToLogin } from "./user";
import { baseURL } from "./config";
import logger from "./logger";
import { parse as parseContentDisposition } from "content-disposition";

const LOCAL_STORAGE_USER_KEY = "user";

const baseAPI = axios.create({
  baseURL,
  xsrfCookieName: "XSRF-TOKEN",
  url: "/",
  headers: {},
  timeout: 5000,
  withCredentials: true,
});

baseAPI.interceptors.response.use(
  (response) => {
    logger.debug(response.config.url, response.data);
    return response;
  },
  (error) => {
    logger.debug(error.config.url, error.data);
    return Promise.reject(error);
  },
);

export function logout(): AxiosPromise<void> {
  return baseAPI.get("/logout");
}

export function login(
  user: UserToLogin,
): AxiosPromise<{
  user: LoggedUser;
  message: string;
}> {
  return baseAPI.post("/login", user);
}

export function getLocalUser(): LoggedUser {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY) as string);
}

export function setLocalUser(user: LoggedUser): void {
  return localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
}

export function isSetLocalUser(): boolean {
  return localStorage.getItem("user") !== null;
}

export function deleteLocalUser(): void {
  return localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
}

export function lostPassword(email: User["email"]): AxiosPromise<void> {
  return baseAPI.post("/users/reset-pwd", { email });
}

export function getUserById(id: User["id"]): AxiosPromise<User> {
  return baseAPI.get("/users/" + id);
}

export function updatePasswordById(
  id: User["id"],
  oldPwd: UserToLogin["password"],
  newPwd: UserToLogin["password"],
): AxiosPromise<void> {
  return baseAPI.put("/users/" + id + "/update-pwd", { oldPwd, newPwd });
}

export interface ObjectURL {
  url: string;
  name?: string;
}

/**
 * Remember to revoke ObjectURL.url when done
 * @param url
 */
export function getObjectURL(url: string): Promise<ObjectURL> {
  return baseAPI
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      return {
        url: window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] }),
        ),
        name: parseContentDisposition(res.headers["content-disposition"])
          .parameters.filename,
      };
    });
}

export function downloadObjectURL(objectUrl: ObjectURL): void {
  const anchor = window.document.createElement("a");
  anchor.href = objectUrl.url;
  if (objectUrl.name) anchor.setAttribute("download", objectUrl.name);

  window.document.body.appendChild(anchor);
  anchor.click();
  window.document.body.removeChild(anchor);
  window.URL.revokeObjectURL(objectUrl.url);
}

export function download(url: string): Promise<void> {
  return getObjectURL(url).then(downloadObjectURL);
}

export default baseAPI;
