import baseAPI from "../auth/api";
import { AxiosPromise, AxiosResponse } from "axios";
import { CompanyForm, Company, CompanyRawList } from "./company";
import { CompanyUser, User, UserForm } from "../auth/user";
import { BaseLicence, Licence } from "../licences/licence";

export function createCompany(
  company: CompanyForm,
): AxiosPromise<CompanyRawList[]> {
  return baseAPI.post("/companies", company);
}

export function deleteCompany(
  companyId: Company["id"],
): AxiosPromise<CompanyRawList[]> {
  return baseAPI.delete(`/companies/${companyId}`);
}

export function updateCompany(
  companyId: Company["id"],
  company: CompanyForm,
): AxiosPromise<CompanyRawList[]> {
  return baseAPI.put(`/companies/${companyId}`, company);
}

export function bulkUpdateCompanyUsers(
  companyId: Company["id"],
  users: UserForm[],
): AxiosPromise<CompanyRawList[]> {
  return baseAPI.post(`/companies/${companyId}/users`, users);
}

export function getCompanyUsersByCompanyId(
  companyId: Company["id"],
): AxiosPromise<CompanyUser[]> {
  return baseAPI.get(`/companies/${companyId}/users`);
}

export function getAllCompanies(): AxiosPromise<CompanyRawList[]> {
  return baseAPI.get("/companies");
}

export function getCompanyById(
  companyId: Company["id"],
): AxiosPromise<CompanyRawList> {
  return baseAPI.get(`/companies/${companyId}`);
}

export function createCompanyUser(
  companyUser: UserForm,
): AxiosPromise<CompanyUser> {
  return baseAPI.post("/users", companyUser);
}

export function deleteCompanyUser(
  userId: User["id"],
): AxiosPromise<AxiosResponse> {
  return baseAPI.delete(`/users/${userId}`);
}

export function updateCompanyUser(
  companyUserId: User["id"],
  companyUser: UserForm,
): AxiosPromise<CompanyUser> {
  return baseAPI.put(`/users/${companyUserId}`, companyUser);
}

export function getAllCompaniesUsers(): AxiosPromise<CompanyUser[]> {
  return baseAPI.get("/users");
}

export function bulkCreateCompanyLicences(
  companyId: Company["id"],
  licences: BaseLicence[],
): AxiosPromise<CompanyRawList> {
  return baseAPI.post(`/companies/${companyId}/licences`, { licences });
}

export function bindLicenceByUserId(
  companyUserId: User["id"],
  companyId: Company["id"],
  licenceId: Licence["id"],
): AxiosPromise<CompanyRawList> {
  return baseAPI.put(`/companies/${companyId}/licences/${companyUserId}`, {
    licenceId,
  });
}

export function unbindLicenceByUserId(
  companyUserId: User["id"],
  companyId: Company["id"],
  licenceId: Licence["id"],
): AxiosPromise<CompanyRawList> {
  return baseAPI.delete(`/companies/${companyId}/licences/${companyUserId}`, {
    data: { licenceId },
  });
}

export function deleteLicence(
  licenceId: Licence["id"],
): AxiosPromise<CompanyRawList> {
  return baseAPI.delete(`/licences/${licenceId}`);
}
