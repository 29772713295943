import { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/services/auth/apiProvider";
import { User } from "../../auth/user";
import { useCompanyManagement } from "../../company-management/apiProvider";
import { Company } from "../../company-management/company";
import { useDate } from "../../date/DateContext";
import { useToasts } from "../../toast-notifications";
import { useConfirmation } from "../../ui/ConfirmationDialog";
import { Licence } from "../licence";

interface Props {
  licence: Licence;
}

const LicenceRow: FunctionComponent<Props> = ({ licence }) => {
  const { user } = useAuth();
  const { formatDate } = useDate();
  const { t } = useTranslation(["companyManagement"]);
  const { error: toastError, success: toastSuccess } = useToasts();
  const { confirm } = useConfirmation();
  const {
    unbindLicenceByUserId: apiUnbindLicenceByUserId,
    deleteLicence: apiDeleteLicence,
  } = useCompanyManagement();

  const unbindLicenceByUserId = useCallback(
    (
      userId: User["id"],
      companyId: Company["id"],
      licenceId: Licence["id"],
    ) => {
      confirm(
        t("companyManagement:UNBIND_LICENCE.CONFIRMATION"),
        () => {
          return apiUnbindLicenceByUserId(userId, companyId, licenceId).then(
            () => {
              toastSuccess(t("companyManagement:UNBIND_LICENCE.TOAST_SUCCESS"));
            },
            () => toastError(t("companyManagement:UNBIND_LICENCE.TOAST_ERROR")),
          );
        },
        true,
      );
    },
    [apiUnbindLicenceByUserId, confirm, t, toastError, toastSuccess],
  );

  return (
    <div className={"table-grid-row"}>
      <div className={"row-m"}>
        <div className={"col-m-2-6"}>
          <label className={"input-label"}>Utilisateur</label>
          {licence.User?.email ?? "-"}
        </div>
        <div className={"col-m-1-6"}>
          <label className={"input-label"}>Durée (mois)</label>
          {licence.monthDuration}
        </div>
        <div className={"col-m-1-6"}>
          <label className={"input-label"}>Début</label>
          {licence.startDate !== null ? formatDate(licence.startDate) : " - "}
        </div>
        <div className={"col-m-1-6"}>
          <label className={"input-label"}>Fin</label>
          {licence.endDate !== null ? formatDate(licence.endDate) : " - "}
        </div>
        <div className={"col-auto"}>
          {licence.User !== null && licence.startDate === null ? (
            <button
              className={"btn-danger"}
              onClick={() =>
                unbindLicenceByUserId(
                  (licence.User as User).id,
                  licence.CompanyId,
                  licence.id,
                )
              }
              type={"button"}
            >
              Délier
            </button>
          ) : null}
        </div>
        <div className={"col-auto"}>
          {user !== null && licence.User === null && user.admin === 1 ? (
            <button
              className={"btn-danger"}
              onClick={() => apiDeleteLicence(licence.id)}
              type={"button"}
            >
              Supprimer
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LicenceRow;
