import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useCompanyManagement } from "../../../services/company-management/apiProvider";
import useLoader from "../../../services/routing/useLoader";
import { useToasts } from "../../../services/toast-notifications";
import { useTranslation } from "react-i18next";
import { searchGenerator } from "../../../services/data-structures/array";
import Loading from "../../../services/routing/components/Loading";
import LoaderErrors from "../../../services/routing/components/LoaderErrors";
import {
  CompanyUser,
  getDefaultUserForm,
  User,
  UserForm,
} from "../../../services/auth/user";
import CompanyUserForm from "../../../services/company-management/components/CompanyUserForm";
import { useConfirmation } from "../../../services/ui/ConfirmationDialog";
import Dialog from "../../../services/ui/elements/Dialog";
import BindLicenceByUserId from "../../../services/licences/components/BindLicenceForm";
import UserLicencesTable from "../../../services/licences/components/UserLicencesTable";

const CompaniesUsers: FunctionComponent<RouteComponentProps> = () => {
  const {
    companies,
    loadCompanies,
    loadCompaniesUsers,
    companiesUsers,
    createCompanyUser: apiCreateCompanyUser,
    deleteCompanyUser: apiDeleteCompanyUser,
    updateCompanyUser: apiUpdateCompanyUser,
    defaultFilteringCompany,
    setDefaultFilteringCompany,
  } = useCompanyManagement();
  const { loading, error, reload } = useLoader(
    () => Promise.all([loadCompaniesUsers(), loadCompanies()]),
    [loadCompaniesUsers, loadCompanies],
  );
  const { error: toastError, success: toastSuccess } = useToasts();
  const { t } = useTranslation(["companyManagement"]);
  const { confirm } = useConfirmation();

  /* Hooks */
  const [search, setSearch] = useState("");
  const [filteredByCompany, setFilteredByCompany] = useState<
    CompanyUser["CompanyId"] | null
  >(defaultFilteringCompany);

  useEffect(() => {
    setDefaultFilteringCompany(null);
  }, [setDefaultFilteringCompany]);

  const [selectedCompanyUser, setSelectedCompanyUser] = useState<
    UserForm | CompanyUser | null
  >(null);

  const filteredCompaniesUsers = useMemo(() => {
    let filteredCompaniesUsers = [...companiesUsers];

    if (filteredByCompany !== null) {
      filteredCompaniesUsers = filteredCompaniesUsers.filter(
        (companyUser) => companyUser.CompanyId === filteredByCompany,
      );
    }

    filteredCompaniesUsers = filteredCompaniesUsers.filter(
      searchGenerator(search),
    );

    filteredCompaniesUsers.sort((a, b) => a.lastname.localeCompare(b.lastname));

    return filteredCompaniesUsers;
  }, [companiesUsers, search, filteredByCompany]);

  /* Handling loading */
  if (loading) return <Loading />;
  if (error) return <LoaderErrors error={error} reload={reload} />;

  /* Methods */
  const createCompanyUser = (companyUser: UserForm): Promise<void> => {
    return apiCreateCompanyUser(companyUser).then(
      () => {
        toastSuccess(t("companyManagement:CREATE_COMPANY_USER.TOAST_SUCCESS"));
        setSelectedCompanyUser(null);
      },
      () => toastError(t("companyManagement:CREATE_COMPANY_USER.TOAST_ERROR")),
    );
  };

  const deleteCompanyUser = (companyUserId: User["id"]) => {
    confirm(
      t("companyManagement:DELETE_COMPANY_USER.CONFIRMATION"),
      () => {
        return apiDeleteCompanyUser(companyUserId).then(
          () =>
            toastSuccess(
              t("companyManagement:DELETE_COMPANY_USER.TOAST_SUCCESS"),
            ),
          () =>
            toastError(t("companyManagement:DELETE_COMPANY_USER.TOAST_ERROR")),
        );
      },
      true,
    );
  };

  const updateCompanyUser = (
    companyUserId: User["id"],
    companyUser: UserForm,
  ): Promise<void> => {
    return apiUpdateCompanyUser(companyUserId, companyUser).then(
      () => {
        toastSuccess(t("companyManagement:UPDATE_COMPANY_USER.TOAST_SUCCESS"));
        setSelectedCompanyUser(null);
      },
      () => toastError(t("companyManagement:UPDATE_COMPANY_USER.TOAST_ERROR")),
    );
  };

  return (
    <div>
      <div className={"section-1"}>
        <button
          className={"btn-1"}
          onClick={() => setSelectedCompanyUser(getDefaultUserForm())}
        >
          Nouvel utilisateur +
        </button>
      </div>

      <div className={"section"}>
        <div className={"grid"}>
          <div className={"col-m-1-3"}>
            <label className={"input-label"}>Rechercher un utilisateur</label>
            <input
              className={"input"}
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder={"Rechercher un utilisateur"}
            />
          </div>
          <div className={"col-m-1-3"}>
            <label className={"input-label"}>Filtrer par entreprise</label>
            <select
              className={"input"}
              onChange={(ev) =>
                setFilteredByCompany(
                  ev.target.value ? parseInt(ev.target.value) : null,
                )
              }
            >
              <option />
              {companies.map((company) => {
                return (
                  <option
                    key={company.id}
                    selected={company.id === filteredByCompany}
                    value={company.id}
                  >
                    {company.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <div className={"table-grid section"}>
        {filteredCompaniesUsers.map((companyUser) => {
          return (
            <div key={companyUser.id} className={"table-grid-row"}>
              <div className={"row-l"}>
                <div className={"col-m-1-6"}>
                  <label className={"input-label"}>Nom</label>
                  {companyUser.lastname}
                </div>
                <div className={"col-m-1-6"}>
                  <label className={"input-label"}>Prénom</label>
                  {companyUser.firstname}
                </div>
                <div className={"col-auto"}>
                  <label className={"input-label"}>E-mail</label>
                  {companyUser.email}
                </div>
                <div className={"col-m-1-6"}>
                  <label className={"input-label"}>Admin</label>
                  {companyUser.admin === 1 || companyUser.admin === 2
                    ? "Oui"
                    : "Non"}
                </div>
                <div className={"col-fit col-actions"}>
                  <button
                    className={"btn-1"}
                    onClick={() => setSelectedCompanyUser(companyUser)}
                  >
                    Voir / Modifier
                  </button>
                  <button
                    className={"btn-danger"}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      deleteCompanyUser(companyUser.id);
                    }}
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {selectedCompanyUser && (
        <Dialog
          onClose={() => setSelectedCompanyUser(null)}
          style={{ width: "90%", maxWidth: 700 }}
        >
          <CompanyUserForm
            companyUser={selectedCompanyUser}
            onSubmit={(companyUser) => {
              if (!!companyUser.id) {
                return updateCompanyUser(companyUser.id, companyUser);
              } else {
                return createCompanyUser(companyUser);
              }
            }}
          />
          {selectedCompanyUser.id !== undefined && (
            <div className={"form-separation"}>
              <UserLicencesTable
                companyUser={selectedCompanyUser as CompanyUser}
              />
              <BindLicenceByUserId
                companyUser={selectedCompanyUser as CompanyUser}
              />
            </div>
          )}
        </Dialog>
      )}
    </div>
  );
};

export default CompaniesUsers;
