import React, { FunctionComponent, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useCompanyManagement } from "../../../services/company-management/apiProvider";
import useLoader from "../../../services/routing/useLoader";
import Loading from "src/services/routing/components/Loading";
import LoaderErrors from "src/services/routing/components/LoaderErrors";
import { useToasts } from "../../../services/toast-notifications";
import { useTranslation } from "react-i18next";
import { searchGenerator } from "../../../services/data-structures/array";
import { Form, Formik } from "formik";
import SubmitButton from "../../../services/forms/SubmitButton";
import TextField from "../../../services/forms/TextField";
import CompanyRow from "../../../services/company-management/components/CompanyRow";

const Companies: FunctionComponent<RouteComponentProps> = () => {
  const {
    loadCompanies,
    companies,
    createCompany: apiCreateCompany,
  } = useCompanyManagement();
  const { loading, error, reload } = useLoader(loadCompanies, [loadCompanies]);
  const { error: toastError, success: toastSuccess } = useToasts();
  const { t } = useTranslation(["companyManagement"]);

  /* Hooks */
  const [search, setSearch] = useState("");

  const filteredCompanies = useMemo(() => {
    let filteredCompanies = [...companies];

    filteredCompanies = filteredCompanies.filter(searchGenerator(search));

    filteredCompanies.sort((a, b) => a.name.localeCompare(b.name));

    return filteredCompanies;
  }, [companies, search]);

  /* Handling loading */
  if (loading) return <Loading />;
  if (error) return <LoaderErrors error={error} reload={reload} />;

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          apiCreateCompany(values).then(
            () => {
              setSubmitting(false);
              toastSuccess(t("companyManagement:CREATE_COMPANY.TOAST_SUCCESS"));
            },
            () => {
              setSubmitting(false);
              toastError(t("companyManagement:CREATE_COMPANY.TOAST_ERROR"));
            },
          );
        }}
      >
        <Form className={"section-1"}>
          <h2 className={"section-title"}>Ajouter une nouvelle entreprise</h2>
          <div className={"row-m"}>
            <div className={"col-auto"}>
              <label className={"input-label"}>Nom de l'entreprise</label>
              <TextField
                className={"input"}
                name={"name"}
                placeholder={"Nom de l'entreprise"}
              />
            </div>
            <div className={"col-fit"}>
              <label className={"input-label"}>&nbsp;</label>
              <SubmitButton className={"btn-1"}>Ajouter +</SubmitButton>
            </div>
          </div>
        </Form>
      </Formik>
      <div className={"section"}>
        <h2 className={"section-title"}>Liste</h2>
        <div className={"row"}>
          <div className={"col-m-1-3"}>
            <input
              className={"input"}
              onChange={(ev) => setSearch(ev.target.value)}
              placeholder={"Rechercher une entreprise"}
            />
          </div>
        </div>

        <div className={"table-grid section"}>
          {filteredCompanies.map((company) => {
            return <CompanyRow key={company.id} company={company} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Companies;
