import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LoggedUser, User, UserToLogin } from "./user";
import baseAPI, {
  deleteLocalUser,
  getLocalUser,
  getUserById,
  isSetLocalUser,
  login as apiLogin,
  logout as apiLogout,
  lostPassword as apiLostPassword,
  setLocalUser,
  updatePasswordById,
} from "./api";
import { AxiosPromise } from "axios";

const defaultUser = isSetLocalUser() ? getLocalUser() : null;

export interface AuthAPI {
  user: LoggedUser | null;

  login(user: UserToLogin): Promise<LoggedUser>;

  logout(): Promise<void>;

  checkUserValidity(): Promise<void>;

  lostPassword(email: User["email"]): AxiosPromise<void>;

  updatePassword(oldPassword: string, newPassword: string): AxiosPromise<void>;
}

export interface AuthAPIConnected extends AuthAPI {
  user: LoggedUser;
}

/**
 * Default value should never be used
 */
export const AuthContext = createContext<AuthAPI | null>(null);

export function useProvideAuth(): AuthAPI {
  const [user, setUser] = useState<LoggedUser | null>(defaultUser);

  useMemo(() => {
    if (user !== null) {
      setLocalUser(user);
      baseAPI.defaults.headers["Authorization"] = `Bearer ${user.xsrfToken}`;
    } else {
      deleteLocalUser();
      delete baseAPI.defaults.headers["Authorization"];
    }
  }, [user]);

  useEffect(() => {
    const interceptor = baseAPI.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error?.response?.status === 401) {
          setUser(null);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      baseAPI.interceptors.response.eject(interceptor);
    };
  }, []);

  const login: AuthAPI["login"] = useCallback((u: UserToLogin) => {
    return apiLogin(u).then((res) => {
      if (res.data.user.admin === 0) return Promise.reject("User is not admin");
      setUser(res.data.user);
      return res.data.user;
    });
  }, []);

  const logout: AuthAPI["logout"] = useCallback(() => {
    setUser(null);
    return apiLogout().then(() => {
      return Promise.resolve();
    });
  }, []);

  const checkUserValidity: AuthAPI["checkUserValidity"] = useCallback(() => {
    if (isSetLocalUser()) {
      const u = getLocalUser() as LoggedUser;
      return getUserById(u.id).then(
        (res) => {
          const newUser = { ...u, ...res.data };
          setUser(newUser);
          setLocalUser(newUser);
        },
        (err) => {
          if (err?.response?.status === 401) {
            setUser(null);
            deleteLocalUser();
          }
        },
      );
    }
    return Promise.resolve();
  }, []);

  const lostPassword: AuthAPI["lostPassword"] = useCallback((email) => {
    return apiLostPassword(email);
  }, []);

  const updatePassword: AuthAPI["updatePassword"] = useCallback(
    (oldPassword, newPassword) => {
      return updatePasswordById(
        (user as LoggedUser).id,
        oldPassword,
        newPassword,
      );
    },
    [user],
  );

  return {
    user,
    login,
    logout,
    checkUserValidity,
    lostPassword,
    updatePassword,
  };
}

export function useAuth(): AuthAPI {
  return useContext(AuthContext) as AuthAPI;
}
