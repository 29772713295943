import {
  CompanyForm,
  Company,
  CompanyList,
  mapCompanyRawToCompany,
} from "./company";
import { createContext, useCallback, useContext, useState } from "react";
import {
  createCompany,
  createCompanyUser,
  deleteCompany,
  deleteCompanyUser,
  getAllCompanies,
  getAllCompaniesUsers,
  getCompanyUsersByCompanyId,
  getCompanyById,
  updateCompany,
  updateCompanyUser,
  bulkCreateCompanyLicences,
  bindLicenceByUserId as apiBindLicenceByUserId,
  unbindLicenceByUserId as apiUnbindLicenceByUserId,
  deleteLicence as apiDeleteLicence,
} from "./api";
import { CompanyUser, User, UserForm } from "../auth/user";
import { Licence, LicenceForm } from "../licences/licence";
import { AuthAPIConnected, useAuth } from "../auth/apiProvider";

export interface CompanyManagementAPI {
  companies: CompanyList[];
  companiesUsers: CompanyUser[];

  defaultFilteringCompany: Company["id"] | null;
  setDefaultFilteringCompany: React.Dispatch<
    React.SetStateAction<Company["id"] | null>
  >;

  loadCompanies(): Promise<void>;
  createCompany(company: CompanyForm): Promise<void>;
  deleteCompany(companyId: Company["id"]): Promise<void>;
  updateCompany(companyId: Company["id"], company: CompanyForm): Promise<void>;

  loadCompaniesUsers(): Promise<void>;
  createCompanyUser(user: UserForm): Promise<void>;
  deleteCompanyUser(userId: User["id"]): Promise<void>;
  updateCompanyUser(userId: User["id"], user: UserForm): Promise<void>;

  createBulkCompanyLicences(
    companyId: Company["id"],
    licences: LicenceForm,
  ): Promise<void>;
  bindLicenceByUserId(
    userId: User["id"],
    companyId: Company["id"],
    licenceId: Licence["id"],
  ): Promise<void>;
  unbindLicenceByUserId(
    userId: User["id"],
    companyId: Company["id"],
    licenceId: Licence["id"],
  ): Promise<void>;
  deleteLicence(licenceId: Licence["id"]): Promise<void>;
}

/**
 * Default value should never be used
 */
export const CompanyManagementContext = createContext<CompanyManagementAPI | null>(
  null,
);

export function useProvideCompanyManagement(): CompanyManagementAPI {
  const [companies, setCompanies] = useState<CompanyList[]>([]);
  const [companiesUsers, setCompaniesUsers] = useState<CompanyUser[]>([]);
  const [defaultFilteringCompany, setDefaultFilteringCompany] = useState<
    Company["id"] | null
  >(null);
  const { user } = useAuth() as AuthAPIConnected;

  return {
    companies,
    companiesUsers,
    defaultFilteringCompany,
    setDefaultFilteringCompany,
    loadCompanies: useCallback(() => {
      if (user.admin === 2 && user.CompanyId) {
        return getCompanyById(user.CompanyId).then((company) =>
          setCompanies([mapCompanyRawToCompany(company.data)]),
        );
      } else {
        return getAllCompanies().then((companies) =>
          setCompanies(
            companies.data.map((company) => mapCompanyRawToCompany(company)),
          ),
        );
      }
    }, [user.CompanyId, user.admin]),
    createCompany: useCallback((company) => {
      return createCompany(company).then((fullCompanies) => {
        setCompanies(
          fullCompanies.data.map((company) => mapCompanyRawToCompany(company)),
        );
      });
    }, []),
    deleteCompany: useCallback((companyId) => {
      return deleteCompany(companyId).then((fullCompanies) => {
        setCompanies(
          fullCompanies.data.map((company) => mapCompanyRawToCompany(company)),
        );
      });
    }, []),
    updateCompany: useCallback((companyId, company) => {
      return updateCompany(companyId, company).then((fullCompanies) => {
        setCompanies(
          fullCompanies.data.map((company) => mapCompanyRawToCompany(company)),
        );
      });
    }, []),
    loadCompaniesUsers: useCallback(() => {
      if (user.admin === 2 && user.CompanyId) {
        return getCompanyUsersByCompanyId(
          user.CompanyId,
        ).then((companiesUsers) => setCompaniesUsers(companiesUsers.data));
      } else {
        return getAllCompaniesUsers().then((companiesUsers) => {
          setCompaniesUsers(companiesUsers.data);
        });
      }
    }, [user.CompanyId, user.admin]),
    createCompanyUser: useCallback((user) => {
      return createCompanyUser(user).then((createdUser) => {
        setCompaniesUsers((companyUsers) => [
          ...companyUsers,
          createdUser.data,
        ]);
      });
    }, []),
    updateCompanyUser: useCallback((userId: User["id"], user) => {
      return updateCompanyUser(userId, user).then((updatedUser) => {
        setCompaniesUsers((companiesUsers) =>
          companiesUsers.map((companyUser) =>
            companyUser.id === updatedUser.data.id
              ? updatedUser.data
              : companyUser,
          ),
        );
      });
    }, []),
    deleteCompanyUser: useCallback((userId) => {
      return deleteCompanyUser(userId).then(() => {
        setCompaniesUsers((users) =>
          users.filter((user) => user.id !== userId),
        );
      });
    }, []),
    createBulkCompanyLicences: useCallback((companyId, licences) => {
      return bulkCreateCompanyLicences(
        companyId,
        [...Array(licences.quantity)].map((value, index) => ({
          monthDuration: licences.monthDuration,
        })),
      ).then((updatedCompany) => {
        setCompanies((companies) =>
          companies.map((company) =>
            company.id === updatedCompany.data.id
              ? mapCompanyRawToCompany(updatedCompany.data)
              : company,
          ),
        );
      });
    }, []),
    bindLicenceByUserId: useCallback((userId, companyId, licenceId) => {
      return apiBindLicenceByUserId(
        userId,
        companyId,
        licenceId,
      ).then((company) => setCompanies([mapCompanyRawToCompany(company.data)]));
    }, []),
    unbindLicenceByUserId: useCallback((userId, companyId, licenceId) => {
      return apiUnbindLicenceByUserId(
        userId,
        companyId,
        licenceId,
      ).then((company) => setCompanies([mapCompanyRawToCompany(company.data)]));
    }, []),
    deleteLicence: useCallback((licenceId) => {
      return apiDeleteLicence(licenceId).then((company) =>
        setCompanies([mapCompanyRawToCompany(company.data)]),
      );
    }, []),
  };
}

export function useCompanyManagement(): CompanyManagementAPI {
  return useContext(CompanyManagementContext) as CompanyManagementAPI;
}
