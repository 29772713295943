/* Public User returned from API */
import { Company } from "../company-management/company";

export interface User {
  id: number;
  email: string;
  lastname: string;
  firstname: string;
  admin: number;
  CompanyId?: Company["id"];
}

/* User for login */
export interface UserToLogin {
  email: string;
  password: string;
}

/* User sent from login */
export interface LoggedUser extends User {
  xsrfToken: string;
}

export function getShortName(user: User): string {
  return user.firstname && user.lastname
    ? `${user.firstname.slice(0, 1)}. ${user.lastname}`
    : "Votre compte";
}

export function getFullName(user: User): string {
  return `${user.firstname} ${user.lastname.toUpperCase()}`;
}

export interface UserForm {
  id?: User["id"];
  email: User["email"];
  firstname: User["firstname"];
  lastname: User["lastname"];
  admin: User["admin"];
}

export interface CompanyUser extends User {
  CompanyId: Company["id"];
}

export function getDefaultUserForm(): UserForm {
  return {
    email: "",
    firstname: "",
    lastname: "",
    admin: 0,
  };
}
