import { CSSProperties, FunctionComponent } from "react";
import { createPortal } from "react-dom";
import useLockBodyScroll from "../useLockBodyScroll";
import { cx } from "@emotion/css";
import iconCloseGrey from "../../../assets/img/icons/icon-close-grey.svg";

interface Props {
  onClose?(): void;
  "aria-describedby"?: string;
  "aria-labelledby"?: string;
  className?: string;
  style?: CSSProperties;
}

const Dialog: FunctionComponent<Props> = ({
  onClose,
  "aria-describedby": ariaDescribedBy,
  "aria-labelledby": ariaLabelledBy,
  children,
  className,
  style,
}) => {
  useLockBodyScroll();

  const template = (
    <div
      aria-describedby={ariaDescribedBy}
      aria-labelledby={ariaLabelledBy}
      className={"modal-overlay"}
    >
      <div className={cx(["dialog", className])} style={style}>
        {onClose && (
          <button className={"close-btn"} onClick={onClose}>
            <img alt={"Fermer"} className={"icon"} src={iconCloseGrey} />
          </button>
        )}

        {children}
      </div>
    </div>
  );

  return createPortal(template, document.body);
};

export default Dialog;
