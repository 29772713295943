import { FunctionComponent, useCallback, useMemo, useState } from "react";
import SimpleInputFile from "../../files/SimpleInputFile";
import { Company, CompanyList } from "../company";
import { UserForm } from "../../auth/user";
import { hasDuplicates } from "../../data-structures/array";
import { readFile, mapCsvToObject } from "../../files/utils";
import { bulkUpdateCompanyUsers } from "../api";
import { useTranslation } from "react-i18next";
import { useToasts } from "../../toast-notifications";
import Dialog from "../../ui/elements/Dialog";
import CompanyDetails from "./CompanyDetails";

interface Props {
  company: CompanyList;
}

const CompanyRow: FunctionComponent<Props> = ({ company }) => {
  const [selectedCompanyUser, setSelectedCompanyUser] = useState<boolean>(
    false,
  );

  const { t } = useTranslation(["companyManagement"]);
  const { error: toastError, success: toastSuccess } = useToasts();

  const activeLicences = useMemo(
    () =>
      company.licences.filter(
        (licence) =>
          licence.startDate !== null &&
          licence.endDate !== null &&
          new Date() <= licence.endDate,
      ),
    [company.licences],
  );

  const uploadCompanyUsers = useCallback(
    (company: Company, files: FileList) => {
      readFile(files[0], "text").then((content) => {
        const users = mapCsvToObject<UserForm>(
          content,
          ["firstname", "lastname", "email"],
          (field) => {
            switch (field) {
              case "prénom":
                return "firstname";
              case "nom":
                return "lastname";
              case "email":
                return "email";
              default:
                return null;
            }
          },
        );

        if (!users) {
          return toastError(
            t(
              "companyManagement:BULK_UPDATE_COMPANY_USERS.TOAST_ERROR_MISSING_FIELD",
            ),
          );
        }

        const completeUsers = users as UserForm[];
        if (
          completeUsers.some(
            (user) =>
              user.email.length === 0 ||
              user.firstname.length === 0 ||
              user.lastname.length === 0,
          )
        ) {
          return toastError(
            t(
              "companyManagement:BULK_UPDATE_COMPANY_USERS.TOAST_ERROR_EMPTY_FIELD",
            ),
          );
        } else if (hasDuplicates(completeUsers.map((user) => user.email))) {
          return toastError(
            t(
              "companyManagement:BULK_UPDATE_COMPANY_USERS.TOAST_ERROR_DUPLICATE_EMAIL",
            ),
          );
        } else {
          return bulkUpdateCompanyUsers(company.id, completeUsers).then(
            () =>
              toastSuccess(
                t("companyManagement:BULK_UPDATE_COMPANY_USERS.TOAST_SUCCESS"),
              ),
            () =>
              toastError(
                t("companyManagement:BULK_UPDATE_COMPANY_USERS.TOAST_ERROR"),
              ),
          );
        }
      });
    },
    [t, toastError, toastSuccess],
  );

  return (
    <div className={"table-grid-row"}>
      <div key={company.id} className={"row-m"}>
        <div className={"col-auto"}>
          <label className={"input-label"}>Nom de l'entreprise</label>
          {company.name}
        </div>
        <div className={"col-auto"}>
          <label className={"input-label"}>Utilisateurs</label>
          {company.userCount}
        </div>
        <div className={"col-auto col-actions"}>
          <label className={"input-label"}>Licences</label>
          {`${activeLicences.length} / ${company.licences.length}`}
        </div>
        <div className={"col-fit col-actions"}>
          <p className={"btn-link hidden"}>
            <label htmlFor={"fileElem"}>Import CSV</label>
            <SimpleInputFile
              accept={"text/csv"}
              id={"fileElem"}
              onChange={(files) => uploadCompanyUsers(company, files)}
            />
          </p>
          <button
            className={"btn-1"}
            onClick={() => setSelectedCompanyUser(true)}
            type={"button"}
          >
            Voir / Modifier
          </button>
        </div>
      </div>
      {selectedCompanyUser && (
        <Dialog
          onClose={() => setSelectedCompanyUser(false)}
          style={{ width: "90%", maxWidth: 900 }}
        >
          <CompanyDetails company={company} />
        </Dialog>
      )}
    </div>
  );
};

export default CompanyRow;
