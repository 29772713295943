import React, { useEffect } from "react";
import "./services/i18n";
import Routes from "./routes";
import { ProvideToast } from "./services/toast-notifications";
import { AuthContext, useProvideAuth } from "./services/auth/apiProvider";
import { ProvideConfirmation } from "./services/ui/ConfirmationDialog";

const App: React.FC = () => {
  const auth = useProvideAuth();

  useEffect(() => {
    auth.checkUserValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      <ProvideToast>
        <ProvideConfirmation>
          <Routes />
        </ProvideConfirmation>
      </ProvideToast>
    </AuthContext.Provider>
  );
};

export default App;
