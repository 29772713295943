/* eslint-disable i18next/no-literal-string */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import AUTH_FR from "../auth/i18n/fr.json";
import VALIDATIONS_FR from "../validations/i18n/fr.json";
import COMPANY_MANAGEMENT_FR from "../company-management/i18n/fr.json";
import { setLocale } from "yup";

i18n.use(initReactI18next).init({
  ns: ["auth", "contributions", "validations", "companyManagement"],
  resources: {
    fr: {
      auth: AUTH_FR,
      validations: VALIDATIONS_FR,
      companyManagement: COMPANY_MANAGEMENT_FR,
    },
  },
  lng: "fr",
  fallbackLng: "fr",

  interpolation: {
    escapeValue: false,
  },
});

export interface ValidationError {
  key: string;
  values: Record<string, unknown>;
}

function getError(key: string) {
  return (values: unknown) => ({ key, values } as ValidationError);
}

setLocale({
  mixed: {
    default: getError("invalid_field"),
    required: getError("required"),
    defined: getError("defined"),
    oneOf: getError("one_of"),
  },
  string: {
    email: getError("email"),
    matches: getError("matches"),
  },
});

export default i18n;
