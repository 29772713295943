import { parseISO } from "date-fns";
import { User } from "../auth/user";
import {
  mapLicenceRawToLicence,
  Licence,
  LicenceRaw,
  LicenceForm,
} from "../licences/licence";

export interface CompanyForm {
  name: string;
}

export interface CompanyBase {
  id: number;
  name: string;
}

export interface CompanyRaw extends CompanyBase {}

export interface Company extends CompanyBase {}

export function mapCompanyRawToCompany(company: CompanyRawList): CompanyList;
export function mapCompanyRawToCompany(company: CompanyRaw): Company;
export function mapCompanyRawToCompany(
  company: CompanyRaw | CompanyRawList,
): Company | CompanyList {
  return {
    ...company,
    lastConnexionDate:
      "lastConnexionDate" in company && company.lastConnexionDate !== null
        ? parseISO(company.lastConnexionDate)
        : null,
    licences:
      "Licences" in company
        ? company.Licences.map((licence) => mapLicenceRawToLicence(licence))
        : [],
  };
}

export interface CompanyList extends Company {
  userCount: number;
  connexionCount: number;
  MonthlyConnexions: MonthlyConnexion[];
  lastConnexionDate: Date | null;
  licences: Licence[];
  Users: User[];
}

export interface CompanyRawList extends CompanyRaw {
  userCount: number;
  connexionCount: number;
  lastConnexionDate: string | null;
  Licences: LicenceRaw[];
  Users: User[];
}

export interface FullCompanyForm extends Company {
  licences: LicenceForm[];
}

export interface MonthlyConnexion {
  connexionCount: number;
  year: number;
  month: number;
}
