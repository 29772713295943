import { fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { Locale, format, parseISO } from "date-fns";

interface DateAPI {
  locale: Locale;
  formatDate: (
    date: Date,
    dateFormat?: string,
    options?: DateFNSFormatOptions,
  ) => string;
  formatISO: (
    iso: string,
    dateFormat?: string,
    options?: DateFNSFormatOptions,
  ) => string;
}

type DateFNSFormatOptions = {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
};

const LOCALES: Record<string, Locale> = {
  fr: fr,
};

export function useDate(): DateAPI {
  const { i18n } = useTranslation();

  const locale = LOCALES[i18n.language] || fr;

  const formatDate = (
    date: Date,
    dateFormat: string = "PP",
    options: DateFNSFormatOptions = { locale },
  ) => {
    return format(date, dateFormat, options);
  };

  const formatISO = (
    iso: string,
    dateFormat: string = "PP",
    options: DateFNSFormatOptions = { locale },
  ) => {
    return formatDate(parseISO(iso), dateFormat, options);
  };

  return {
    locale,
    formatDate,
    formatISO,
  };
}
