import { parseISO } from "date-fns";
import { User } from "../auth/user";
import { Company } from "../company-management/company";

export interface BaseLicence {
  monthDuration: number;
}

export interface Licence extends BaseLicence {
  id: number;
  startDate: Date | null;
  endDate: Date | null;
  User: User | null;
  CompanyId: Company["id"];
}

export interface LicenceRaw extends BaseLicence {
  id: number;
  startDate: string | null;
  endDate: string | null;
  User: User | null;
  CompanyId: Company["id"];
}

export function mapLicenceRawToLicence(licence: LicenceRaw): Licence {
  return {
    ...licence,
    startDate: licence.startDate !== null ? parseISO(licence.startDate) : null,
    endDate: licence.endDate !== null ? parseISO(licence.endDate) : null,
  };
}

export interface LicenceForm {
  monthDuration: number;
  quantity: number;
}

export enum LicenceStatus {
  used,
  unused,
  expirated,
}
