import React, { FunctionComponent, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useCompanyManagement } from "../../../services/company-management/apiProvider";
import useLoader from "../../../services/routing/useLoader";
import Loading from "src/services/routing/components/Loading";
import LoaderErrors from "src/services/routing/components/LoaderErrors";
import { useDate } from "../../../services/date/DateContext";
import {
  CompanyUser,
  UserForm,
  User,
  getDefaultUserForm,
} from "../../../services/auth/user";
import { useTranslation } from "react-i18next";
import { useConfirmation } from "../../../services/ui/ConfirmationDialog";
import { useToasts } from "../../../services/toast-notifications";
import CompanyUserForm from "../../../services/company-management/components/CompanyUserForm";
import BindLicenceByUserId from "../../../services/licences/components/BindLicenceForm";
import UserLicencesTable from "../../../services/licences/components/UserLicencesTable";
import Dialog from "../../../services/ui/elements/Dialog";
import { searchGenerator } from "../../../services/data-structures/array";
import { LicenceStatus } from "../../../services/licences/licence";
import LicenceRow from "../../../services/licences/components/LicenceRow";

const Company: FunctionComponent<RouteComponentProps> = () => {
  const {
    companies,
    companiesUsers,
    loadCompanies,
    loadCompaniesUsers,
    createCompanyUser: apiCreateCompanyUser,
    deleteCompanyUser: apiDeleteCompanyUser,
    updateCompanyUser: apiUpdateCompanyUser,
  } = useCompanyManagement();

  const [selectedCompanyUser, setSelectedCompanyUser] = useState<
    UserForm | CompanyUser | null
  >(null);
  const [search, setSearch] = useState("");
  const [
    filteredByLicenceStatus,
    setFilteredByLicenceStatus,
  ] = useState<LicenceStatus>(LicenceStatus.used);

  const { formatDate } = useDate();
  const { error: toastError, success: toastSuccess } = useToasts();
  const { t } = useTranslation(["companyManagement"]);
  const { confirm } = useConfirmation();
  const { loading, error, reload } = useLoader(
    () => Promise.all([loadCompanies(), loadCompaniesUsers()]),
    [loadCompanies, loadCompaniesUsers],
  );

  const filteredCompaniesUsers = useMemo(() => {
    let filteredCompaniesUsers = [...companiesUsers];
    filteredCompaniesUsers = filteredCompaniesUsers.filter(
      searchGenerator(search),
    );
    filteredCompaniesUsers.sort((a, b) => a.lastname.localeCompare(b.lastname));
    return filteredCompaniesUsers;
  }, [companiesUsers, search]);

  const filteredLicences = useMemo(() => {
    if (companies.length === 0) return [];
    const company = companies[0];
    switch (filteredByLicenceStatus) {
      case LicenceStatus.used:
        return company.licences.filter(
          (licence) =>
            licence.startDate !== null &&
            licence.endDate &&
            new Date() <= licence.endDate,
        );
      case LicenceStatus.unused:
        return company.licences.filter((licence) => licence.startDate === null);
      case LicenceStatus.expirated:
        return company.licences.filter(
          (licence) =>
            licence.startDate !== null &&
            licence.endDate &&
            new Date() > licence.endDate,
        );
    }
  }, [companies, filteredByLicenceStatus]);

  const activeLicences = useMemo(
    () =>
      companies.length !== 0
        ? companies[0].licences.filter(
            (licence) =>
              licence.startDate !== null &&
              licence.endDate !== null &&
              new Date() <= licence.endDate,
          )
        : [],
    [companies],
  );

  const [filteredMonth, setFilteredMonth] = useState<number>(
    new Date().getMonth() + 1,
  );
  const [filteredYear, setFilteredYear] = useState<number>(
    new Date().getFullYear(),
  );

  const months = [
    { key: "Janvier", value: 1 },
    { key: "Février", value: 2 },
    { key: "Mars", value: 3 },
    { key: "Avril", value: 4 },
    { key: "Mai", value: 5 },
    { key: "Juin", value: 6 },
    { key: "Juillet", value: 7 },
    { key: "Août", value: 8 },
    { key: "Septembre", value: 9 },
    { key: "Octobre", value: 10 },
    { key: "Novembre", value: 11 },
    { key: "Décembre", value: 12 },
  ];
  const currentMonth = new Date().getMonth() + 1;

  const years = [];
  const startYear = 2022;
  for (let year = new Date().getFullYear(); year >= startYear; year--) {
    years.push(year);
  }

  /* Handling loading */
  if (loading || companies.length === 0) return <Loading />;
  if (error) return <LoaderErrors error={error} reload={reload} />;

  /* Methods */
  const createCompanyUser = (companyUser: UserForm): Promise<void> => {
    return apiCreateCompanyUser(companyUser).then(
      () => {
        toastSuccess(t("companyManagement:CREATE_COMPANY_USER.TOAST_SUCCESS"));
        setSelectedCompanyUser(null);
      },
      () => toastError(t("companyManagement:CREATE_COMPANY_USER.TOAST_ERROR")),
    );
  };

  const deleteCompanyUser = (companyUserId: User["id"]) => {
    confirm(
      t("companyManagement:DELETE_COMPANY_USER.CONFIRMATION"),
      () => {
        return apiDeleteCompanyUser(companyUserId).then(
          () =>
            toastSuccess(
              t("companyManagement:DELETE_COMPANY_USER.TOAST_SUCCESS"),
            ),
          () =>
            toastError(t("companyManagement:DELETE_COMPANY_USER.TOAST_ERROR")),
        );
      },
      true,
    );
  };

  const updateCompanyUser = (
    companyUserId: User["id"],
    companyUser: UserForm,
  ): Promise<void> => {
    return apiUpdateCompanyUser(companyUserId, companyUser).then(
      () => {
        toastSuccess(t("companyManagement:UPDATE_COMPANY_USER.TOAST_SUCCESS"));
        setSelectedCompanyUser(null);
      },
      () => toastError(t("companyManagement:UPDATE_COMPANY_USER.TOAST_ERROR")),
    );
  };

  const company = companies[0];

  return (
    <div>
      <div className={"section"}>
        <h2 className={"section-title"}>{company.name}</h2>
        <div className={"form-block form-separation"}>
          <div className={"table-grid"}>
            <div className={"row-m"}>
              <div className={"col-m-2-6"}>
                <div className={"card flex-center --stretch"}>
                  <label className={"label-bold"}>Utilisateurs</label>
                  <p className={"kpi"}>{company.userCount}</p>
                </div>
              </div>
              <div className={"col-m-2-6"}>
                <div className={"card flex-center"}>
                  <label className={"label-bold"}>
                    Nb total d'utilisations de l'application
                  </label>
                  <p className={"kpi --alt1"}> {company.connexionCount}</p>
                </div>
                <div className={"card flex-center margin-top"}>
                  <label className={"label-bold"}>
                    Date de dernière connexion
                  </label>
                  <p className={"chip"}>
                    {company.lastConnexionDate !== null
                      ? formatDate(company.lastConnexionDate)
                      : "Aucune connexion"}
                  </p>
                </div>
              </div>
              <div className={"col-m-2-6"}>
                <div className={"card flex-center --stretch"}>
                  <label className={"label-bold"}>
                    Nombre d'utilisations en
                  </label>
                  <div className={"inputs-row margin-top"}>
                    <select
                      className={"select"}
                      defaultValue={currentMonth}
                      name={"monthlyConnexionMonthFilter"}
                      onChange={(ev) =>
                        setFilteredMonth(Number(ev.target.value))
                      }
                    >
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.key}
                        </option>
                      ))}
                    </select>
                    <select
                      className={"select"}
                      name={"monthlyConnexionYearFilter"}
                      onChange={(ev) =>
                        setFilteredYear(Number(ev.target.value))
                      }
                    >
                      {years.map((year) => (
                        <option key={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                  <p className={"kpi --alt2"}>
                    {company.MonthlyConnexions.find(
                      (mc) =>
                        mc.year === filteredYear && mc.month === filteredMonth,
                    )?.connexionCount || 0}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"section form-separation"}>
        <button
          className={"btn-1"}
          onClick={() =>
            setSelectedCompanyUser({
              ...getDefaultUserForm(),
              CompanyId: company.id,
            })
          }
        >
          Nouvel utilisateur +
        </button>
      </div>
      <div className={"grid"}>
        <div className={"col-m-1-3"}>
          <label className={"input-label"}>Rechercher un utilisateur</label>
          <input
            className={"input"}
            onChange={(ev) => setSearch(ev.target.value)}
            placeholder={"Rechercher un utilisateur"}
          />
        </div>
      </div>

      <div className={"table-grid section"}>
        {filteredCompaniesUsers.map((companyUser) => {
          return (
            <div key={companyUser.id} className={"table-grid-row"}>
              <div className={"row-l"}>
                <div className={"col-m-1-6"}>
                  <label className={"input-label"}>Nom</label>
                  {companyUser.lastname}
                </div>
                <div className={"col-m-1-6"}>
                  <label className={"input-label"}>Prénom</label>
                  {companyUser.firstname}
                </div>
                <div className={"col-auto"}>
                  <label className={"input-label"}>E-mail</label>
                  {companyUser.email}
                </div>
                <div className={"col-m-1-6"}>
                  <label className={"input-label"}>Admin</label>
                  {companyUser.admin === 1 || companyUser.admin === 2
                    ? "Oui"
                    : "Non"}
                </div>
                <div className={"col-fit col-actions"}>
                  <button
                    className={"btn-1"}
                    onClick={() => setSelectedCompanyUser(companyUser)}
                  >
                    Voir / Modifier
                  </button>
                  <button
                    className={"btn-danger"}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      deleteCompanyUser(companyUser.id);
                    }}
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={"section"}>
        <div className={"form-block form-separation"}>
          <label className={"label-bold"}>Licences existantes</label>
          <p
            className={"chip --inline"}
          >{`${activeLicences.length} / ${company.licences.length} licences actives`}</p>
          <div className={"radio-row"}>
            <label>
              <input
                name={"licenceStatusFilter"}
                onChange={() => setFilteredByLicenceStatus(LicenceStatus.used)}
                type={"radio"}
                defaultChecked
              />
              Licences en cours d'utilisation
            </label>
            <label>
              <input
                name={"licenceStatusFilter"}
                onChange={() =>
                  setFilteredByLicenceStatus(LicenceStatus.unused)
                }
                type={"radio"}
              />
              Licences disponibles
            </label>
            <label>
              <input
                name={"licenceStatusFilter"}
                onChange={() =>
                  setFilteredByLicenceStatus(LicenceStatus.expirated)
                }
                type={"radio"}
              />
              Licences expirées
            </label>
          </div>
          <div className={"table-grid section"}>
            {filteredLicences.length > 0 ? (
              filteredLicences.map((licence) => (
                <LicenceRow key={licence.id} licence={licence} />
              ))
            ) : (
              <p className={"info"}>Aucune licence trouvée...</p>
            )}
          </div>
        </div>
      </div>

      {selectedCompanyUser && (
        <Dialog onClose={() => setSelectedCompanyUser(null)}>
          <CompanyUserForm
            companyUser={selectedCompanyUser}
            onSubmit={(companyUser) => {
              if (!!companyUser.id) {
                return updateCompanyUser(companyUser.id, companyUser);
              } else {
                return createCompanyUser(companyUser);
              }
            }}
          />
          {selectedCompanyUser.id !== undefined && (
            <div className={"form-separation"}>
              <UserLicencesTable
                companyUser={selectedCompanyUser as CompanyUser}
              />
              <BindLicenceByUserId
                companyUser={selectedCompanyUser as CompanyUser}
              />
            </div>
          )}
        </Dialog>
      )}
    </div>
  );
};

export default Company;
