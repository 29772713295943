import React, { FunctionComponent, useMemo } from "react";
import { Form, Formik, Field } from "formik";
import Link from "../../routing/components/Link";
import { LOST_PASSWORD_LINK } from "../../../routes/public";
import { object, SchemaOf, string } from "yup";
import { useTranslation } from "react-i18next";
import { UserToLogin } from "../user";
import { SUBMIT_BUTTON_ID } from "../../../fixtures/auth";
import ValidationsErrors from "../../forms/ValidationsErrors";
import SubmitButton from "../../forms/SubmitButton";

interface Props {
  onSubmit: (
    values: UserToLogin,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void;
}

const LoginForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const { t } = useTranslation(["auth"]);

  const LoginSchema: SchemaOf<UserToLogin> = useMemo(
    () =>
      object()
        .shape({
          email: string().label(t("auth:EMAIL")).required().email(),
          password: string().label(t("auth:PASSWORD")).required(),
        })
        .defined(),
    [t],
  );

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(LoginSchema.validateSync(values), setSubmitting);
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={LoginSchema}
    >
      <Form>
        <div className={"form-block"}>
          <label className={"input-label"} htmlFor={"email-input"}>
            {t("auth:EMAIL")} *
          </label>
          <Field
            className={"input"}
            id={"email-input"}
            name={"email"}
            placeholder={"e.g. john.doe@mail.com"}
          />
        </div>
        <div className={"form-block"}>
          <label className={"input-label"} htmlFor={"password-input"}>
            {t("auth:PASSWORD")} *
          </label>
          <Field
            className={"input"}
            id={"password-input"}
            name={"password"}
            placeholder={"***********"}
            type={"password"}
          />
        </div>

        <div>
          <ValidationsErrors />
        </div>

        <div className={"center"}>
          <SubmitButton className={"btn-1"} data-testid={SUBMIT_BUTTON_ID}>
            Connexion
          </SubmitButton>
          <Link className={"btn-link"} to={LOST_PASSWORD_LINK}>
            Mot de passe oublié ?
          </Link>
        </div>
      </Form>
    </Formik>
  );
};

export default LoginForm;
