import { FunctionComponent } from "react";
import { Form, Formik } from "formik";
import { number, object } from "yup";
import TextField from "../../forms/TextField";
import SubmitButton from "../../forms/SubmitButton";
import { useCompanyManagement } from "../../company-management/apiProvider";
import { CompanyList } from "../../company-management/company";
import { useToasts } from "../../toast-notifications";
import { useTranslation } from "react-i18next";

const LicenceSchema = object({
  quantity: number().label("Quantité").required(),
  monthDuration: number().label("Durée (mois)").required(),
});

interface Props {
  company: CompanyList;
}

const LicenceForm: FunctionComponent<Props> = ({ company }) => {
  const { createBulkCompanyLicences } = useCompanyManagement();

  const { t } = useTranslation(["companyManagement"]);
  const { error: toastError, success: toastSuccess } = useToasts();

  return (
    <Formik
      initialValues={{ monthDuration: null, quantity: null }}
      onSubmit={(values, { setSubmitting }) => {
        createBulkCompanyLicences(company.id, {
          ...LicenceSchema.validateSync(values),
        })
          .then(
            () => {
              toastSuccess(t("companyManagement:CREATE_LICENCE.TOAST_SUCCESS"));
            },
            () => toastError(t("companyManagement:CREATE_LICENCE.TOAST_ERROR")),
          )
          .finally(() => {
            setSubmitting(false);
          });
      }}
      validateOnBlur={false}
      validateOnMount={false}
      validationSchema={LicenceSchema}
    >
      <Form className={"section"}>
        <div className={"inline-div"}>
          <span>Ajouter </span>
          <TextField
            className={"input input-inline"}
            name={"quantity"}
            placeholder={"Quantité..."}
          />
          <span> nouvelle licences de </span>
          <TextField
            className={"input input-inline"}
            name={"monthDuration"}
            placeholder={"Durée..."}
          />
          <span> mois.</span>{" "}
        </div>

        <SubmitButton className={"btn-1"}>Ajouter</SubmitButton>
      </Form>
    </Formik>
  );
};

export default LicenceForm;
