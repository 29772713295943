import React from "react";
import { Redirect, Router } from "@reach/router";
import Public from "../views/public/Public";
import { PUBLIC } from "./public";
import Private from "../views/private/Private";
import { PRIVATE } from "./private";
import { useAuth } from "../services/auth/apiProvider";

export const HOME = "/";

const Routes: React.FC = () => {
  const { user } = useAuth();

  return (
    <Router>
      <Redirect from={HOME} to={PUBLIC} default noThrow />
      <Public path={`${PUBLIC}/*`} />

      {user !== null && <Private path={`${PRIVATE}/*`} />}
    </Router>
  );
};

export default Routes;
