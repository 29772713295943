import { FunctionComponent, useMemo, useState } from "react";
import { CompanyList } from "../company";
import CompanyForm from "./CompanyForm";
import LicenceForm from "../../licences/components/LicenceForm";
import LicenceRow from "../../licences/components/LicenceRow";
import Link from "../../routing/components/Link";
import { USERS_LINK } from "../../../routes/companyManagement";
import { useCompanyManagement } from "../apiProvider";
import { useDate } from "../../date/DateContext";
import { LicenceStatus } from "../../licences/licence";

interface Props {
  company: CompanyList;
}

const CompanyDetails: FunctionComponent<Props> = ({ company }) => {
  const [
    filteredByLicenceStatus,
    setFilteredByLicenceStatus,
  ] = useState<LicenceStatus>(LicenceStatus.used);

  const [filteredMonth, setFilteredMonth] = useState<number>(
    new Date().getMonth() + 1,
  );
  const [filteredYear, setFilteredYear] = useState<number>(
    new Date().getFullYear(),
  );

  const { formatDate } = useDate();

  const { setDefaultFilteringCompany } = useCompanyManagement();

  const months = [
    { key: "Janvier", value: 1 },
    { key: "Février", value: 2 },
    { key: "Mars", value: 3 },
    { key: "Avril", value: 4 },
    { key: "Mai", value: 5 },
    { key: "Juin", value: 6 },
    { key: "Juillet", value: 7 },
    { key: "Août", value: 8 },
    { key: "Septembre", value: 9 },
    { key: "Octobre", value: 10 },
    { key: "Novembre", value: 11 },
    { key: "Décembre", value: 12 },
  ];
  const currentMonth = new Date().getMonth() + 1;

  const years = [];
  const startYear = 2022;
  for (let year = new Date().getFullYear(); year >= startYear; year--) {
    years.push(year);
  }

  const activeLicences = useMemo(
    () =>
      company.licences.filter(
        (licence) =>
          licence.startDate !== null &&
          licence.endDate !== null &&
          new Date() <= licence.endDate,
      ),
    [company.licences],
  );

  const filteredLicences = useMemo(() => {
    switch (filteredByLicenceStatus) {
      case LicenceStatus.used:
        return company.licences.filter(
          (licence) =>
            licence.startDate !== null &&
            licence.endDate &&
            new Date() <= licence.endDate,
        );
      case LicenceStatus.unused:
        return company.licences.filter((licence) => licence.startDate === null);
      case LicenceStatus.expirated:
        return company.licences.filter(
          (licence) =>
            licence.startDate !== null &&
            licence.endDate &&
            new Date() > licence.endDate,
        );
    }
  }, [company.licences, filteredByLicenceStatus]);

  return (
    <>
      <div className={"card-head"}>
        <h2 className={"card-title"}>Entreprise</h2>
      </div>

      <CompanyForm company={company} />

      <div className={"form-block form-separation"}>
        <div className={"table-grid"}>
          <div className={"row-m --stretch"}>
            <div className={"col-m-2-6"}>
              <div className={"card flex-center --stretch"}>
                <label className={"label-bold"}>Utilisateurs</label>
                <p className={"kpi"}>{company.userCount} </p>
                <Link
                  className={"link-s"}
                  onClick={() => setDefaultFilteringCompany(company.id)}
                  to={USERS_LINK}
                >
                  {"tous les utilisateurs >"}
                </Link>
              </div>
            </div>
            <div className={"col-m-2-6"}>
              <div className={"card flex-center"}>
                <label className={"label-bold"}>
                  Nb total d'utilisations de l'application
                </label>
                <p className={"kpi --alt1"}>{company.connexionCount}</p>
              </div>
              <div className={"card flex-center margin-top"}>
                <label className={"label-bold"}>
                  Date de dernière connexion
                </label>
                <p className={"chip"}>
                  {company.lastConnexionDate !== null
                    ? formatDate(company.lastConnexionDate)
                    : "Aucune connexion"}
                </p>
              </div>
            </div>
            <div className={"col-m-2-6"}>
              <div className={"card flex-center --stretch"}>
                <label className={"label-bold"}>Nombre d'utilisations en</label>
                <div className={"inputs-row margin-top"}>
                  {" "}
                  <select
                    className={"select"}
                    defaultValue={currentMonth}
                    name={"monthlyConnexionMonthFilter"}
                    onChange={(ev) => setFilteredMonth(Number(ev.target.value))}
                  >
                    {months.map((month) => (
                      <option key={month.value} value={month.value}>
                        {month.key}
                      </option>
                    ))}
                  </select>
                  <select
                    className={"select"}
                    name={"monthlyConnexionYearFilter"}
                    onChange={(ev) => setFilteredYear(Number(ev.target.value))}
                  >
                    {years.map((year) => (
                      <option key={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <p className={"kpi --alt2"}>
                  {company.MonthlyConnexions.find(
                    (mc) =>
                      mc.year === filteredYear && mc.month === filteredMonth,
                  )?.connexionCount || 0}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"form-block form-separation"}>
        <label className={"label-bold"}>Nouvelles licences</label>
        <LicenceForm company={company} />
      </div>

      <div className={"form-block form-separation"}>
        <label className={"label-bold"}>Licences existantes</label>
        <p
          className={"chip --inline"}
        >{`${activeLicences.length} / ${company.licences.length} licences actives`}</p>
        <>
          <div className={"radio-row"}>
            <label>
              <input
                name={"licenceStatusFilter"}
                onChange={() => setFilteredByLicenceStatus(LicenceStatus.used)}
                type={"radio"}
                defaultChecked
              />
              Licences en cours d'utilisation
            </label>
            <label>
              <input
                name={"licenceStatusFilter"}
                onChange={() =>
                  setFilteredByLicenceStatus(LicenceStatus.unused)
                }
                type={"radio"}
              />
              Licences disponibles
            </label>
            <label>
              <input
                name={"licenceStatusFilter"}
                onChange={() =>
                  setFilteredByLicenceStatus(LicenceStatus.expirated)
                }
                type={"radio"}
              />
              Licences expirées
            </label>
          </div>
          <div className={"table-grid section"}>
            {filteredLicences.length > 0 ? (
              filteredLicences.map((licence) => (
                <LicenceRow key={licence.id} licence={licence} />
              ))
            ) : (
              <p className={"info"}>Aucune licence trouvée...</p>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default CompanyDetails;
