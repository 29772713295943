export const PUBLIC = "/public" as const;

export const LOGIN = "/login" as const;
export const LOGIN_LINK = `${PUBLIC}${LOGIN}` as const;

export const REGISTER = "/register" as const;
export const REGISTER_LINK = `${PUBLIC}${REGISTER}` as const;

export const LOST_PASSWORD = "/lost-password" as const;
export const LOST_PASSWORD_LINK = `${PUBLIC}${LOST_PASSWORD}` as const;

export const PRIVACY = "/privacy" as const;
export const PRIVACY_LINK = `${PUBLIC}${PRIVACY}` as const;
