import React, { FunctionComponent, useMemo } from "react";
import { Form, Formik, Field } from "formik";
import { date, number, object, string } from "yup";
import { useTranslation } from "react-i18next";
import FSelectParse from "../../forms/FSelectParse";
import { useCompanyManagement } from "../apiProvider";
import Loading from "../../routing/components/Loading";
import LoaderErrors from "../../routing/components/LoaderErrors";
import useLoader from "../../routing/useLoader";
import ValidationsErrors from "../../forms/ValidationsErrors";
import SubmitButton from "../../forms/SubmitButton";
import { CompanyUser, UserForm } from "../../auth/user";
import { PartialNullable } from "../../types/utility";

interface Props {
  companyUser: PartialNullable<UserForm> | CompanyUser;
  onSubmit(companyUser: UserForm): void;
}

const CompanyUserForm: FunctionComponent<Props> = ({
  companyUser: companyUserProps,
  onSubmit,
}) => {
  const { companies, loadCompanies } = useCompanyManagement();
  const { loading, error, reload } = useLoader(loadCompanies, [loadCompanies]);

  const { t } = useTranslation(["auth", "companyManagement"]);

  const CompanyUserSchema = useMemo(
    () =>
      object({
        email: string().label(t("auth:EMAIL")).email().required(),
        firstname: string().label(t("auth:FIRSTNAME")).required(),
        lastname: string().label(t("auth:LASTNAME")).required(),
        birthdate: date().label(t("auth:BIRTHDATE")).required(),
        CompanyId: number()
          .label(t("companyManagement:COMPANY"))
          .nullable()
          .required(),
        admin: number().label(t("companyManagement:COMPANY_ADMIN")).required(),
      }),
    [t],
  );

  /* Handling loading */
  if (loading) return <Loading />;
  if (error) return <LoaderErrors error={error} reload={reload} />;

  const companyUser = companyUserProps;

  const isCreating = !("id" in companyUserProps);

  return (
    <Formik
      initialValues={{
        ...companyUser,
        adminCheck: companyUser.admin === 2 ? true : false,
      }}
      onSubmit={(values) => {
        if (companyUser.admin !== 1) values.admin = values.adminCheck ? 2 : 0;
        onSubmit(CompanyUserSchema.validateSync(values));
      }}
      validateOnBlur={false}
      validateOnMount={false}
      validationSchema={CompanyUserSchema}
    >
      <Form>
        <div className={"card-head"}>
          <h2 className={"card-title"}>Utilisateur</h2>
        </div>

        <div className={"card-body"}>
          <div className={"form-block"}>
            <label className={"input-label"}>{t("auth:FIRSTNAME")}</label>
            <Field className={"input"} name={"firstname"} />
          </div>

          <div className={"form-block"}>
            <label className={"input-label"}>{t("auth:LASTNAME")}</label>
            <Field className={"input"} name={"lastname"} />
          </div>

          <div className={"form-block"}>
            <label className={"input-label"}>{t("auth:EMAIL")}</label>
            <Field
              className={"input"}
              disabled={!isCreating}
              name={"email"}
              type={"email"}
            />
          </div>

          <div className={"form-block"}>
            <label className={"input-label"}>{t("auth:BIRTHDATE")}</label>
            <Field className={"input"} name={"birthdate"} type={"date"} />
          </div>

          <div className={"form-block"}>
            <label className={"input-label"}>
              {t("companyManagement:COMPANY")}
            </label>
            <FSelectParse name={"CompanyId"}>
              <option />
              {companies.map((company) => {
                return (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                );
              })}
            </FSelectParse>
          </div>

          {companyUser.admin !== 1 && (
            <div className={"form-block"}>
              <label className={"input-label"}>
                <Field name={"adminCheck"} type={"checkbox"} />
                {t("companyManagement:COMPANY_ADMIN")}
              </label>
            </div>
          )}

          <div>
            <ValidationsErrors />
          </div>

          <div>
            <SubmitButton className={"btn-1"}>
              {!isCreating ? "Enregistrer" : "Créer"}
            </SubmitButton>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default CompanyUserForm;
