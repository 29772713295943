import React, { FunctionComponent } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import Profile from "./Profile";
import { PRIVATE, PROFILE, PROFILE_LINK } from "../../routes/private";
import { getShortName } from "../../services/auth/user";
import NavLink from "../../services/routing/components/NavLink";
import Link from "../../services/routing/components/Link";
import { LOGOUT_BUTTON_ID } from "../../fixtures/auth";
import { useAuth, AuthAPIConnected } from "../../services/auth/apiProvider";
import CompanyManagement from "./company-management/CompanyManagement";
import {
  COMPANY_DASHBOARD,
  COMPANY_DASHBOARD_LINK,
  COMPANY_MANAGEMENT,
  COMPANY_MANAGEMENT_LINK,
} from "../../routes/companyManagement";
import logo from "../../assets/img/logo.svg";
import CompanyDashboard from "./company-dashboard/CompanyDashboard";

const HOME = "/";

const Private: FunctionComponent<RouteComponentProps> = () => {
  const { user, logout } = useAuth() as AuthAPIConnected;

  return (
    <div className={"app-layout"}>
      <header className={"header"}>
        <Link to={PRIVATE}>
          <img alt={"logo 2U"} className={"logo-header"} src={logo} />
        </Link>
        <nav className={"nav"}>
          <ul>
            <li>
              <NavLink to={PROFILE_LINK}>
                <span>{getShortName(user)}</span>
              </NavLink>
            </li>
            <li>
              <button
                className={"btn-link"}
                data-testid={LOGOUT_BUTTON_ID}
                onClick={logout}
                type={"button"}
              >
                <span>Déconnexion</span>
              </button>
            </li>
          </ul>
        </nav>
      </header>

      <main className={"content"}>
        <Router>
          {user.admin === 1 && (
            <>
              <Redirect
                from={HOME}
                to={COMPANY_MANAGEMENT_LINK}
                default
                noThrow
              />
              <Profile path={PROFILE} />
              <CompanyManagement path={COMPANY_MANAGEMENT + "/*"} />
            </>
          )}
          {user.admin === 2 && (
            <>
              <Redirect
                from={HOME}
                to={COMPANY_DASHBOARD_LINK}
                default
                noThrow
              />
              <Profile path={PROFILE} />
              <CompanyDashboard path={COMPANY_DASHBOARD + "/*"} />
            </>
          )}
        </Router>
      </main>
    </div>
  );
};

export default Private;
