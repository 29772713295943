import { FunctionComponent } from "react";
import { RouteComponentProps, Router } from "@reach/router";
import {
  CompanyManagementContext,
  useProvideCompanyManagement,
} from "../../../services/company-management/apiProvider";
import Company from "./Company";

const CompanyDashboard: FunctionComponent<RouteComponentProps> = () => {
  const companyManagement = useProvideCompanyManagement();

  return (
    <CompanyManagementContext.Provider value={companyManagement}>
      <div className={"container page-content"}>
        <Router>
          <Company path={"*"} />
        </Router>
      </div>
    </CompanyManagementContext.Provider>
  );
};

export default CompanyDashboard;
