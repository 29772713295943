import React from "react";
import { RouteComponentProps } from "@reach/router";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import { useToasts } from "../../services/toast-notifications";
import LoginForm from "../../services/auth/components/LoginForm";
import { useAuth } from "../../services/auth/apiProvider";
import { useTranslation } from "react-i18next";

const Login: React.FC<RouteComponentProps> = () => {
  const { login } = useAuth();
  const { error } = useToasts();
  const { t } = useTranslation(["auth"]);

  return (
    <div className={"auth-card"}>
      <div className={"card-head"}>
        <h1 className={"card-title"}>Connexion</h1>
      </div>
      <div className={"card-body"}>
        <LoginForm
          onSubmit={(loginInfos, setSubmitting) => {
            login(loginInfos).then(
              () => {
                setSubmitting(false);
              },
              () => {
                error(t("auth:LOGIN.TOAST_ERROR"));
                setSubmitting(false);
              },
            );
          }}
        />
      </div>
    </div>
  );
};

export default withRedirectToPrivate(Login);
