import React, { FunctionComponent, useMemo } from "react";
import { Form, Formik } from "formik";
import { number, object } from "yup";
import SubmitButton from "../../forms/SubmitButton";
import { useCompanyManagement } from "../../company-management/apiProvider";
import { CompanyUser } from "../../auth/user";
import FSelectParse from "../../forms/FSelectParse";

const BindLicenceSchema = object({
  licenceId: number().label("Licence ID").required(),
});

interface Props {
  companyUser: CompanyUser;
}

const BindLicenceByUserId: FunctionComponent<Props> = ({ companyUser }) => {
  const { bindLicenceByUserId, companies } = useCompanyManagement();

  const userCompany = useMemo(
    () => companies.find((company) => company.id === companyUser.CompanyId),
    [companies, companyUser.CompanyId],
  );

  const unbindedLicencesSorted = useMemo(
    () =>
      userCompany?.licences
        .filter((licence) => licence.User === null, [])
        .sort((a, b) => a.monthDuration - b.monthDuration),
    [userCompany?.licences],
  );

  return (
    <Formik
      initialValues={{ licenceId: null }}
      onSubmit={(values, { setSubmitting }) => {
        bindLicenceByUserId(
          companyUser.id,
          companyUser.CompanyId,
          BindLicenceSchema.validateSync(values).licenceId,
        ).finally(() => {
          setSubmitting(false);
        });
      }}
      validateOnBlur={false}
      validateOnMount={false}
      validationSchema={BindLicenceSchema}
    >
      <Form className={"section"}>
        <div className={"inline-div"}>
          <span>Affecter une nouvelle licence </span>
          <FSelectParse className={"input input-inline"} name={"licenceId"}>
            <option />
            {unbindedLicencesSorted?.map((licence) => {
              return (
                <option key={licence.id} value={licence.id}>
                  {licence.monthDuration} mois
                </option>
              );
            })}
          </FSelectParse>
        </div>

        <SubmitButton className={"btn-1"}>OK</SubmitButton>
      </Form>
    </Formik>
  );
};

export default BindLicenceByUserId;
